export default {
  background: "panorama_mobiliteit_leeg.png",
  tag: "Voor het bedrijfsleven",
  elements: [
    {
      type: "question",
      position: {
        x: 1,
        y: 65,
      },
      size: {
        width: 7,
        height: 32,
      },
      text: "Deze voorstellen maakten we voor het bedrijfsleven. Stemmen kan door hiernaast op een van de opties te klikken.",
      css: {
        color: "rgb(227, 173, 22)",
      },
      dimensions: [1, 1],
      textStyle: {
        "font-size": "7px",
        "font-weight": "normal",
      },
      className: "questionLabel",
    },
    {
      type: "icon",
      icon: "info",
      position: {
        x: 1,
        y: 61,
      },
      size: {
        width: 7,
        height: 4,
      },
      css: {
        color: "rgb(227, 173, 22)",
        paddingTop: "2vh",
      },
      dimensions: [1, 1],
      className: "questionLabel",
    },
    {
      type: "questionSelector",
      // left: 9.5%;
      // top: 34%;
      // width: 15%;
      // height: 43%;
      position: {
        x: 9.5,
        y: 34,
      },
      size: {
        width: 15,
        height: 43,
      },
    },
    // {
    //   type: "text",
    //   position: {
    //     x: 8,
    //     y: 20,
    //   },
    //   size: {
    //     width: 14,
    //     height: 9,
    //   },
    //   text: "VOORSTEL:",
    //   css: {
    //     color: "rgb(227, 173, 22)",
    //   },
    //   dimensions: [1, 1],
    //   textStyle: {
    //     "font-size": "4.5px",
    //   },
    //   className: "questionLabel",
    // },
    // {
    //   type: "voorstel",
    //   position: {
    //     x: 8,
    //     y: 29,
    //   },
    //   size: {
    //     width: 14,
    //     height: 26,
    //   },
    //   text: "",
    //   css: {
    //     color: "rgb(227, 173, 22)",
    //   },
    //   dimensions: [1, 1],
    //   textStyle: {
    //     "font-size": "4.5px",
    //   },
    //   className: "questionLabel",
    // },
    {
      type: "button",
      // left: 22.5%;
      // top: 20%;
      // width: 8.01085%;
      buttonValue: "eens",
      position: {
        x: 24.7,
        y: 34,
      },
      size: {
        width: 5.5,
        height: 8,
      },
      text: "Eens",
      css: {
        color: "rgb(227, 173, 22)",
      },
      dimensions: [1, 1],
      textStyle: {
        "font-size": "14px",
        "text-align": "center",
        "line-height": "0.5",
      },
      className: "voteButton",
    },
    {
      type: "button",
      buttonValue: "oneens",
      position: {
        x: 24.7,
        y: 44,
      },
      size: {
        width: 5.5,
        height: 8,
      },
      text: "Oneens",
      css: {
        color: "rgb(227, 173, 22)",
      },
      dimensions: [1, 1],
      textStyle: {
        "font-size": "14px",
        "text-align": "center",
        "line-height": "0.5",
      },
      className: "voteButton",
    },
    {
      type: "button",
      buttonValue: "mits",
      position: {
        x: 24.7,
        y: 54,
      },
      size: {
        width: 5.5,
        height: 16,
      },
      text: "Eens, maar alleen als",
      css: {
        color: "rgb(227, 173, 22)",
      },
      dimensions: [1, 1],
      textStyle: {
        "font-size": "14px",
        "text-align": "center",
        "line-height": "0.5",
      },
      className: "voteButton",
    },
    {
      type: "questionSelector",
      position: {
        x: 56,
        y: 3,
      },
      size: {
        width: 12,
        height: 55,
      },
    },
    {
      type: "graph",
      position: {
        x: 47.5,
        y: 32,
      },
      questionId: "rVXG3xg5QT1Mx5Ip1Xp2",
      size: {
        width: 11,
        height: 51,
      },
      text: "",
      css: {},
      graphBase: {
        question: "",
        answers: [
          {
            ans: "",
            amount: 0,
          },
        ],
        questionId: "",
      },
      dimensions: [4, 2],
      colorScheme: 0,
      labelStyle: {
        color: "#ffffff",
      },
    },
    {
      type: "question",
      position: {
        x: 39,
        y: 82,
      },
      size: {
        width: 9,
        height: 18,
      },
      text: "Zo is er gestemd op de voorstellen",
      css: {
        color: "rgb(227, 173, 22)",
      },
      dimensions: [1, 1],
      textStyle: {
        "font-size": "8px",
        "font-weight": "normal",
      },
      className: "questionLabel",
    },
    {
      type: "icon",
      icon: "info",
      position: {
        x: 39,
        y: 78,
      },
      size: {
        width: 9,
        height: 4,
      },
      css: {
        color: "rgb(227, 173, 22)",
        paddingTop: "2vh",
      },
      dimensions: [1, 1],
      className: "questionLabel",
    },
    {
      type: "text",
      position: {
        x: 71,
        y: 82.60000000000001,
      },
      size: {
        width: 9,
        height: 15,
      },
      text: "Dit zijn onze werkplannen",
      css: {
        color: "rgb(227, 173, 22)",
        zIndex: 99,
      },
      dimensions: [1, 1],
      textStyle: {
        "font-size": "7px",
        "font-weight": "normal",
      },
      className: "questionLabel",
    },
    {
      type: "icon",
      icon: "info",
      position: {
        x: 71,
        y: 78.60000000000001,
      },
      size: {
        width: 9,
        height: 4,
      },
      css: {
        color: "rgb(227, 173, 22)",
        paddingTop: "2vh",
        zIndex: 99,
      },
      dimensions: [1, 1],
      className: "questionLabel",
    },
    {
      type: "image-container",
      // left: 74.1%;
      // top: 27.6%;
      // width: 16.5%;
      // height: 45.2%;
      position: {
        x: 74.1,
        y: 27.6,
      },
      questionId: "xvkyqCrchwxJ3V6LWeDA",
      size: {
        width: 16.5,
        height: 45.2,
      },
      text: "",
      css: {},
      columns: 4,
      limit: 6,
    },
  ],
};
