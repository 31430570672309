import React, { useEffect, useState } from "react";
import ImageMapper from "react-image-mapper";
import ProgressiveImage from "react-progressive-image";
import { RouteComponentProps } from "react-router-dom";
import IntroScreen from "./IntroScreen";
import OrientationOverlay from "./OrientationOverlay";
import "./landingPage.css";
import Straatverklaring from "../Straatverklaringen/Straatverklaring";

import { useHistory } from "react-router-dom";
import { css } from "emotion";

interface LandingPageProps {
  preLaunch: boolean;
  onPlay: () => void;
}

interface Props extends RouteComponentProps<any>, LandingPageProps {}

const LandingPage = (props: Props) => {
  const [showStraatverklaring, setShowStraatverklaring] = useState(false);
  const backgroundLayers = ["01", "02"];
  const frontLayer = "03";
  const { match, history, preLaunch, onPlay } = props;
  const routeChange = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    console.log("match change", match);
    if (match.path.startsWith("/straatverklaringen")) {
      setShowStraatverklaring(true);
    }
  }, [match]);

  let showIntroscreen = history.action === "POP" ? true : false; // checks if user comes from same domain

  const toggleStraatverklaring = () => {
    setShowStraatverklaring((prevState) => !prevState);
  };

  // Check for landscape
  const isLandscape = () =>
    window.matchMedia("(orientation:landscape)").matches;

  const [orientation, setOrientation] = useState(
    isLandscape() ? "landscape" : "portrait"
  );

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [windowAspectRatio, setWindowAspectRatio] = useState(
    window.innerWidth / window.innerHeight
  );
  const onWindowResize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    setOrientation(isLandscape() ? "landscape" : "portrait");
    setWindowAspectRatio(window.innerWidth / window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => window.removeEventListener("resize", onWindowResize);
  }, []);

  const imageAspectRatio = 1474 / 931;
  const [imageSize, setImageSize] = useState<{
    width: number;
    height: number;
  }>({
    height:
      windowAspectRatio < imageAspectRatio
        ? windowSize.height
        : windowSize.width / imageAspectRatio,
    width:
      windowAspectRatio < imageAspectRatio
        ? windowSize.height * imageAspectRatio
        : windowSize.width,
  });
  useEffect(() => {
    setImageSize({
      height:
        windowAspectRatio < imageAspectRatio
          ? windowSize.height
          : windowSize.width / imageAspectRatio,
      width:
        windowAspectRatio < imageAspectRatio
          ? windowSize.height * imageAspectRatio
          : windowSize.width,
    });
    window.scrollTo(0, (imageSize.height - windowSize.height) / 2);
  }, [windowSize, windowAspectRatio]);

  const MAP = {
    name: "my-map",
    areas: [
      {
        name: "bordjes",
        shape: "poly",
        coords: [550, 250, 800, 250, 800, 550, 550, 550],
      },
      {
        name: "audio",
        shape: "poly",
        coords: [850, 200, 1000, 200, 1000, 350, 850, 350],
      },
      {
        name: "straatverklaring",
        shape: "poly",
        coords: [200, 450, 400, 450, 400, 600, 200, 600],
      },
      {
        name: "agenda",
        shape: "poly",
        coords: [1000, 520, 1100, 520, 1100, 670, 1000, 670],
      },
    ],
  };

  const imageClicked = (area: any) => {
    if (!preLaunch) {
      switch (area.name) {
        case "bordjes":
          routeChange("/bordjes");
          break;
        case "audio":
          onPlay();
          break;
        case "agenda":
          routeChange("/agenda");
          break;
        case "straatverklaring":
          toggleStraatverklaring();
          break;
        default:
          break;
      }
    }
  };

  const displayFrontLayer = (
    <div className="frontImage">
      <ProgressiveImage
        src={`/assets/background_${frontLayer}.png`}
        placeholder={`/assets/small/background_${frontLayer}.png`}
      >
        {(src: string) => {
          return (
            <ImageMapper
              src={src}
              map={MAP}
              height={imageSize.height}
              width={imageSize.width}
              imgWidth={1474}
              fillColor="rgba(0,0,0,0)"
              strokeColor="rgba(0,0,0,0)"
              onClick={(area: any) => imageClicked(area)}
            />
          );
        }}
      </ProgressiveImage>
    </div>
  );

  const allBackgroundLayers = backgroundLayers.map(
    (layer: string, index: number) => (
      <div className="backgroundImage" key={`bg-image-${index}`}>
        <ProgressiveImage
          src={`/assets/background_${layer}.png`}
          placeholder={`/assets/small/background_${layer}.png`}
        >
          {(src: string) => {
            return (
              <img
                className={`backgroundImage${layer}`}
                src={src}
                alt=""
                height={imageSize.height}
                width={imageSize.width}
              />
            );
          }}
        </ProgressiveImage>
      </div>
    )
  );

  const InteractionLayer = (props: any) =>
    preLaunch ? (
      <div />
    ) : (
      <img
        className="interactionLayer"
        src={`/assets/background_04.png`}
        alt="interaction hint"
        height={imageSize.height}
        width={imageSize.width}
        {...props}
      />
    );

  const introscreen = showIntroscreen ? <IntroScreen /> : null;

  const landingPage =
    orientation === "portrait" ? (
      <OrientationOverlay />
    ) : (
      <>
        {introscreen}
        <div
          className={css`
            position: absolute;
            top: 0;
            bottom: 0;
            /* left: ${(windowSize.width - imageSize.width) / 2}px; */
            width: ${imageSize.width}px;
            /* overflow: hidden; */
          `}
        >
          {allBackgroundLayers}
          {displayFrontLayer}
          <InteractionLayer />
        </div>
      </>
    );

  const straatverklaring = showStraatverklaring ? (
    <Straatverklaring click={toggleStraatverklaring} />
  ) : null;

  return (
    <div style={{ height: "100%" }}>
      {landingPage}
      {straatverklaring}
    </div>
  );
};

export default LandingPage;
