import React, { HTMLProps } from "react";

const Image = (props: Props & HTMLProps<HTMLDivElement>) => {
  //  const element = props.imageUrl
  const {
    element: { imageUrl },
  } = props;
  return (
    <div>
      <img 
        src={imageUrl} 
        style={{
          position:"relative",
          width: "100%"
          }}

        alt="filler" />
    </div>
  );
};

export default Image;
