import React, { useState } from "react";

// text nog in een apart bestand zetten

function IntroScreen() {
  const [startClicked, setStartClicked] = useState(false); // dit moet straks gewoon in de landingspage

  if (!startClicked) {
    return (
      <div className="intro-screen">
        <div></div>
        <div className="intro-screen-text">
          <div className="intro-text bold">Welkom!</div>
          <div className="intro-text">
            Welkom op Het Grote Straatberaad! Wij, bewoners uit heel Nederland,
            gingen met elkaar in beraad over het klimaat. Op deze website vind
            je onze voorstellen en onze Straatverklaring.
            <br></br>
            Wil je ook meedoen met jouw straat, voetbalclub of vriendengroep? Op
            de pagina INSCHRIJVEN vind je de informatie over hoe je je kan
            aanmelden!
          </div>
          <button onClick={() => setStartClicked(true)}>START</button>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default IntroScreen;
