import { css } from "emotion";
import _, { values } from "lodash";
import React, {
  HTMLProps,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Pie } from "react-chartjs-2";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { FirebaseContext } from "../../../firebase";
import { GlobalStateContext } from "../../../global-state";
import { mq } from "../../../utils/media-query";
import { planMapping } from "../../../utils/plan-mapping";
import usePrevious from "../../../utils/use-previous";
import { PopupContext } from "../../pop-up";

const colorSchemes = [
  ["#3817ab", "#2f6fff", "#00acff", "#00a67e", "#056a24"],
  ["#d52618", "#e70696", "#9c002a", "#e6b016", "#ff8100"],
];

type QuestionData = {
  labels: string[];
  datasets: {
    data: number[];
    backgroundColor: string[];
    borderWidth: number;
    answer_choice_index: number[];
    answer_text: string[];
    answers: MultipleChoiceAnswer[][];
  }[];
  question: string;
};
type AnswersForQuestionData = {
  [index: number]: {
    label: string;
    amount: number;
    answer_choice_index: number;
    answer_text: string;
    answers: MultipleChoiceAnswer[];
  };
};

function createQuestionData(
  answers: AnswersForQuestionData,
  question: string,
  colorScheme: number
): QuestionData {
  console.log("huh", { answers });
  return {
    labels: _.map(answers, "label"),
    datasets: [
      {
        data: _.map(answers, "amount"),
        backgroundColor: colorSchemes[colorScheme].map((color) => color),
        borderWidth: 0,
        answer_choice_index: _.map(answers, "answer_choice_index"),
        answer_text: _.map(answers, "answer_text"),
        answers: _.map(answers, "answers"),
      },
    ],
    question: question,
  };
}

const getTextSize = () => {
  if (window.innerHeight < 500) {
    return 10;
  } else {
    return 12;
  }
};

const getPaddingSize = () => {
  if (window.innerHeight < 500) {
    return 4;
  } else {
    return 4;
  }
};

const Graph = (props: Props & HTMLProps<HTMLDivElement>) => {
  const { element } = props;
  const labelStyle = element.labelStyle ? element.labelStyle : {};
  const firebase = useContext(FirebaseContext);
  const { selectedVoorstel } = useContext(GlobalStateContext);
  const prevSelectedVoorstel = usePrevious(selectedVoorstel);
  const popupContext = useContext(PopupContext);
  const [answers] = useCollectionData<MultipleChoiceAnswer>(
    firebase.firestore.collection("collected_data").where(
      "question_id",
      "==",
      selectedVoorstel
        ? planMapping.reduce((acc, item) => {
            if (selectedVoorstel.data()?.question_id === item.planId) {
              return item.votesId;
            }
            return acc;
          }, "")
        : element.questionId
    )
  );

  const [votes] = useDocumentData<VoteItem>(
    selectedVoorstel
      ? firebase.firestore.collection("votes").doc(
          selectedVoorstel
            ? planMapping.reduce((acc, item) => {
                if (selectedVoorstel.data()?.question_id === item.planId) {
                  return item.planId;
                }
                return acc;
              }, undefined as undefined | string)
            : undefined
        )
      : null
  );

  const [answersLoaded, setAnswersLoaded] = useState(false);
  const [questionData, setQuestionData] = useState<QuestionData>();

  const [textSize, setTextSize] = useState(getTextSize());
  const [paddingSize, setPaddingSize] = useState(getPaddingSize());
  const [colorScheme, setColorScheme] = useState(0);

  const [flipflop, setFlipflop] = useState(true);

  useEffect(() => {
    if (
      selectedVoorstel &&
      prevSelectedVoorstel &&
      selectedVoorstel.id !== prevSelectedVoorstel.id
    ) {
      setTimeout(() => setFlipflop(!flipflop), 100);
    }
  }, [flipflop, selectedVoorstel, prevSelectedVoorstel]);

  const pieRef = useRef<Pie>(null);

  if (colorScheme !== element.colorScheme) {
    setColorScheme(() => element.colorScheme);
  }

  window.onresize = function (event: any) {
    setTextSize(() => getTextSize());
    setPaddingSize(() => getPaddingSize());
  };

  const options = {
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  };

  useEffect(() => {
    if (!answers && !votes) {
      return;
    }
    setAnswersLoaded(true);
    console.log({ answers });
    const questionData = createQuestionData(
      [...(answers ? answers : []), ...values(votes as VoteItem)].reduce(
        (acc, answer) => {
          if (!acc[answer.answer_choice_index]) {
            acc[answer.answer_choice_index] = {
              label: selectedVoorstel
                ? ["eens", "oneens", "eens, maar alleen als..."][
                    answer.answer_choice_index
                  ]
                : answer.answer_text,
              amount: 0,
              answer_choice_index: answer.answer_choice_index,
              answer_text: answer.answer_text,
              answers: answers ? answers : [],
            };
          }
          acc[answer.answer_choice_index].amount += 1;
          return acc;
        },
        {} as AnswersForQuestionData
      ),
      "question text",
      colorScheme
    );
    setQuestionData(questionData);
  }, [answers, answersLoaded, colorScheme, votes]);

  const [legendRendered, setLegendRendered] = useState(false);

  const plugins = [
    {
      afterDatasetsDraw() {
        // hack to force re-render component in order to show legend
        if (!legendRendered) {
          setLegendRendered(true);
        }
      },
    },
  ];

  const openConditionsPopup = () => {
    const filteredAnswers = [...answers!, ...values(votes as VoteItem)].filter(
      (answer) => answer.answer_choice_index === 2
    );
    popupContext.setContent(
      <div>
        <div
          className={css`
            margin-bottom: 16px;
          `}
        >
          <div>Wij waren het eens met dit voorstel, maar alleen als...</div>
        </div>

        <ul
          className={css`
            list-style: none;
            padding-left: 2em;
          `}
        >
          {filteredAnswers?.map((answer) => (
            <li
              className={css`
                position: relative;
                &::before {
                  content: "-";
                  position: absolute;
                  top: 0;
                  left: -2em;
                }
              `}
            >
              {answer.answer_text}
            </li>
          ))}
        </ul>
      </div>,
      selectedVoorstel
        ? "Voorstel: " + selectedVoorstel?.data()?.answer_text
        : ""
    );
  };
  return (
    <>
      {legendRendered && pieRef.current && (
        <div
          className={css`
            margin-bottom: ${labelStyle.marginBottom
              ? labelStyle.marginBottom
              : "16px"};
            background: ${labelStyle.containerBackground
              ? labelStyle.containerBackground
              : "none"};
            padding: ${labelStyle.containerPadding
              ? labelStyle.containerPadding
              : "0px"};
          `}
        >
          {pieRef.current.chartInstance.legend.legendItems.map(
            (item: any, i: number) => {
              const answer_choice_index =
                questionData?.datasets[0].answer_choice_index[i];
              return (
                <div
                  key={`label-${i}-${item.text}`}
                  className={css`
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                    cursor: ${answer_choice_index === 2
                      ? "pointer"
                      : "initial"};
                  `}
                  onClick={() => {
                    if (answer_choice_index === 2) {
                      openConditionsPopup();
                    }
                  }}
                >
                  <div
                    className={css`
                      width: 15px;
                      height: 15px;
                      background: ${item.fillStyle};
                      margin: 0px 8px 2px 0;
                      display: inline-block;
                      ${mq()} {
                        width: 8px;
                        height: 8px;
                        margin-right: 4px;
                      }
                    `}
                  />
                  <div
                    className={css`
                      flex: 1;
                      color: ${labelStyle.color ? labelStyle.color : "white"};
                      ${mq()} {
                        font-size: 8px;
                      }
                      line-height: 1;
                      &::first-letter {
                        text-transform: capitalize;
                      }
                    `}
                  >
                    {item.text} {flipflop ? "" : " "}
                  </div>
                </div>
              );
            }
          )}
        </div>
      )}
      {questionData && (
        <Pie
          ref={pieRef}
          plugins={plugins}
          width={element.dimensions[0]}
          height={element.dimensions[1]}
          data={questionData}
          options={options}
          getElementAtEvent={(e) => {
            const answer_choice_index =
              questionData.datasets[0].answer_choice_index[e[0]._index];
            if (answer_choice_index === 2) {
              openConditionsPopup();
            }
          }}
        />
      )}
      <div className={css``}></div>
    </>
  );
};

export default Graph;
