import React, { useContext, useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { FirebaseContext } from "../../firebase";
import _ from "lodash";
import { useParams } from "react-router";
import { css } from "emotion";
import { firestore } from "firebase";

Font.register({
  family: "PoppinsLight",
  src: "/assets/fonts/Poppins-Light.ttf",
});
Font.register({
  family: "PoppinsExtraBold",
  src: "/assets/fonts/Poppins-ExtraBold.ttf",
});

// Create styles
const styles = StyleSheet.create({
  viewer: {
    width: "100%",
    height: "100%",
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    fontFamily: `PoppinsLight`,
    paddingBottom: 86,
    paddingTop: 32,
    paddingHorizontal: 64,
  },
  header: {},
  headerImage: {
    width: "100%",
    margin: "0px 0px 16px",
  },
  footer: {
    position: "absolute",
    bottom: 32,
    left: 64,
    right: 64,
  },
  footerImage: {
    width: "100%",
  },
  body: {},
  section: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "10px 0px",
  },
  title: {
    textTransform: "uppercase",
    fontFamily: "PoppinsExtraBold",
  },
  paragraph: {
    marginBottom: 15,
    lineHeight: 1.5,
  },
  inlineText: {
    fontSize: 10,
  },
  variable: {
    color: "#e70696",
  },
});

const StraatverklaringPDF = () => {
  const { declarationId } = useParams<{ declarationId: string }>();
  const firebase = useContext(FirebaseContext);
  const [declaration, setDeclaration] = useState<Declaration>();

  useEffect(() => {
    firebase.firestore
      .collection("events")
      .get()
      .then((events) => {
        const declarationsPromises = events.docs.map((event) => {
          return firebase.firestore
            .collection(`events/${event.id}/declarations`)
            .get()
            .then((results) =>
              results.docs
                .filter((result) => result.id === declarationId)
                .map((result) => ({
                  id: result.id,
                  content: result.data().declaration,
                  eventDeclaration: event.data().event_declaration,
                }))
            );
        });
        // .filter((docs) => docs.length);
        Promise.all(declarationsPromises).then((declarations) => {
          setDeclaration(_.flatten(declarations)[0]);
        });
      });
  }, []);

  if (!declaration) {
    return (
      <span
        className={css`
          color: white;
          padding: 32px;
          display: block;
        `}
      >
        Laden.......
      </span>
    );
  }

  const { streetIndex, cityIndex } = declaration.eventDeclaration.reduce(
    (acc, item, index) => {
      if (item.phrase_function === "street") {
        acc.streetIndex = index;
      }
      if (item.phrase_function === "city") {
        acc.cityIndex = index;
      }
      return acc;
    },
    {} as { streetIndex?: number; cityIndex?: number }
  );
  if (typeof streetIndex === "undefined" || typeof cityIndex === "undefined") {
    return null;
  }

  if (!streetIndex) {
    return null;
  }

  console.log({ declaration });
  return (
    <PDFDownloadLink
      className={css`
        color: white;
        padding: 32px;
        display: block;
      `}
      document={
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.header}>
              <View style={styles.section}>
                <Image
                  style={styles.headerImage}
                  src="/assets/pdf-header.png"
                />
              </View>
            </View>
            <View style={[styles.section, styles.title]}>
              <Text>STRAATVERKLARING </Text>
              <Text style={styles.variable}>
                {declaration.content[streetIndex].phrase_text.toUpperCase()}
              </Text>
            </View>
            <View style={styles.section}>
              {/* {declaration.content.map((item) => (
                <Text>{item.phrase_text}</Text>
              ))} */}
              {declaration.content
                .reduce(
                  (sections, item, i) => {
                    const currentSection = sections[sections.length - 1];
                    if (
                      currentSection.length &&
                      currentSection[currentSection.length - 1].phrase_type ===
                        "text" &&
                      item.phrase_type === "text"
                    ) {
                      sections.push([]);
                    }
                    sections[sections.length - 1].push(item);
                    return sections;
                  },
                  [[]] as { phrase_text: string; phrase_type: string }[][]
                )
                .map((section, sectionIndex) => {
                  console.log(section);
                  return (
                    <Text
                      style={styles.paragraph}
                      key={`declarationSection-${sectionIndex}`}
                    >
                      {section.map((declarationItem, i) => (
                        <Text
                          key={`declarationItem-${sectionIndex}-${i}`}
                          style={[
                            styles.inlineText,
                            declarationItem.phrase_type === "variable"
                              ? styles.variable
                              : {},
                          ]}
                          // className={`verklaring verklaring--${declarationItem.phrase_type}`}
                        >
                          {" "}
                          {declarationItem.phrase_text}
                        </Text>
                      ))}
                    </Text>
                  );
                })}
            </View>
            <View style={styles.footer} fixed>
              <Image style={styles.footerImage} src="/assets/pdf-footer.png" />
            </View>
          </Page>
        </Document>
      }
      fileName={`straatverklaring-${declarationId}.pdf`}
    >
      {({ blob, url, loading, error }) =>
        loading ? "Laden..." : "Klik hier om de straatverklaring te downloaden!"
      }
    </PDFDownloadLink>
  );
};

export default StraatverklaringPDF;
