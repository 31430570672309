import { css } from "emotion";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Straat from "./Straat";
import Straten from "./Straten";

function Straatverklaring(props: any) {
  const { declarationId } = useParams<{ declarationId: string }>();
  const history = useHistory();
  const straatElement = declarationId ? (
    <Straat declarationId={declarationId} />
  ) : (
    <Straten />
  );
  const shareUrl = `https://hetgrotestraatberaad.nl/straatverklaringen/${declarationId}`;
  const shareQuote =
    "Onze straat ging in gesprek over het klimaat tijdens Het Grote Straatberaad. Bekijk hier onze Straatverklaring. Benieuwd waar andere straten door heel Nederland het over hadden? Of wil jij ook meedoen met jouw straat, vriendengroep of sportvereniging? Kijk dan op www.hetgrotestraatberaad.nl";
  const [showShareMenu, setShowShareMenu] = useState(false);

  return (
    <div className="intro-screen intro-screen--close straatverklaring">
      <div className="white close big clickable" onClick={props.click}>
        <img
          className={css`
            width: 48px;
          `}
          src="/assets/icon-close.png"
          alt="close"
        />
      </div>
      {declarationId && (
        <div
          className="back blue clickable intro-screen__back"
          onClick={() => history.push("/straatverklaringen")}
        >
          {`< Andere straatverklaringen`}
        </div>
      )}
      <div className="scroll">
        <div className="inner">{straatElement}</div>
      </div>
      {declarationId && (
        <>
          <div
            className={css`
              padding: 16px 32px 0;
            `}
          >
            <Link
              className={css`
                color: #ff8100;
                font-size: 18px;
                margin-right: 16px;
              `}
              to={`/straatverklaringen/${declarationId}/pdf`}
              target="_blank"
            >
              Download deze straatverklaring
            </Link>
            <span
              className={css`
                color: #ff8100;
                font-size: 18px;
                margin-right: 16px;
                text-decoration: underline;
                cursor: pointer;
              `}
              onClick={() => setShowShareMenu(true)}
            >
              Delen
            </span>
          </div>
          {showShareMenu && (
            <div
              className={css`
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 350px;
                background: #000;
                color: #fff;
                padding: 16px;
              `}
            >
              <div>Delen</div>
              <div
                className={css`
                  display: flex;
                  justify-content: space-around;
                `}
              >
                <FacebookShareButton url={shareUrl} quote={shareQuote}>
                  {" "}
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <EmailShareButton url={shareUrl} body={shareQuote}>
                  {" "}
                  <EmailIcon size={32} round={true} />
                </EmailShareButton>
                <LinkedinShareButton url={shareUrl} summary={shareQuote}>
                  {" "}
                  <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
                <TelegramShareButton url={shareUrl}>
                  {" "}
                  <TelegramIcon size={32} round={true} />
                </TelegramShareButton>
                <TwitterShareButton url={shareUrl}>
                  {" "}
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <WhatsappShareButton url={shareUrl}>
                  {" "}
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
              </div>
              <div>
                <input
                  type="text"
                  readOnly
                  value={shareUrl}
                  className={css`
                    width: 100%;
                  `}
                />
              </div>
              <img
                className={css`
                  width: 32px;
                  position: absolute;
                  top: 16px;
                  right: 16px;
                  cursor: pointer;
                `}
                onClick={() => setShowShareMenu(false)}
                src="/assets/icon-close.png"
                alt="close"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Straatverklaring;
