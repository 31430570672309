import React, { HTMLProps, useContext } from "react";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { FirebaseContext } from "../firebase";

const QuestionTest = (props: Props & HTMLProps<HTMLDivElement>) => {
  const firebase = useContext(FirebaseContext);
  const [value, loading, error] = useCollectionOnce(
    firebase.firestore.collection("collected_data")
  );
  const [questionsValue] = useCollectionOnce(
    firebase.firestore.collection("questions")
  );
  return (
    <div style={{ background: "#fff" }}>
      {error && <div>error: {JSON.stringify(error)}</div>}
      {value && questionsValue && (
        <pre>
          {JSON.stringify(
            value.docs
              .map((doc: any) => doc.data())
              .reduce((acc: any, val: any) => {
                if (!acc[val.question_id]) {
                  const questionDoc = questionsValue.docs.filter(
                    (doc: any) => doc.id === val.question_id
                  )[0];
                  acc[val.question_id] = questionDoc
                    ? {
                        question: questionDoc.data().question_text,
                        type: questionDoc.data().question_type,
                        answers: [],
                      }
                    : {};
                }
                if (acc[val.question_id].answers) {
                  if (
                    acc[val.question_id].type === "image" ||
                    acc[val.question_id].type === "video"
                  ) {
                    acc[val.question_id].answers.push(val.answer_media_uri);
                  } else {
                    acc[val.question_id].answers.push(val.answer_text);
                  }
                }
                return acc;
              }, {} as { [key: string]: { question: string; answers: string[]; type: string } }),
            null,
            2
          )}
        </pre>
      )}
      {loading && <div>loading</div>}
    </div>
  );
};

export default QuestionTest;
