import React from "react";
import VideoComponent from "../components/Elements/innerElements/VideoComponent";

const VideoTest = () => {
  return (
    <div>
      <VideoComponent />
    </div>
  );
};

export default VideoTest;
