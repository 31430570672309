import React from "react";

const VideoComponent = (props: any) => {
/*      src="https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_640_3MG.mp4"*/
  return (
    <video
      style ={{
        position:"relative",
        width:"100%"
        }}
      src={props.element.videoLink}
      controls
      {...props}
    />
  );
};

export default VideoComponent;
