import React, { HTMLProps, useContext } from "react";
import { FirebaseContext } from "../../../firebase";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";

const TextQuestion = (props: Props & HTMLProps<HTMLDivElement>) => {
  const {
    element: { questionId },
  } = props;
  const firebase = useContext(FirebaseContext);
  const [value, loading, error] = useCollectionDataOnce(
    questionId
      ? firebase.firestore
          .collection("collected_data")
          .doc(questionId)
          .collection("answers")
      : null
  );
  /*  console.log(value)*/
  return (
    <div>
      {error && <div>error: {JSON.stringify(error)}</div>}
      {value && <div>{JSON.stringify(value, null, 2)}</div>}
      {loading && <div>loading</div>}
    </div>
  );
};

export default TextQuestion;
