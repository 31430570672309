import React from "react";
import { css } from "emotion";

function Agenda() {
  return (
    <>
      <img
        src="/assets/agenda.png"
        alt=""
        className={css`
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          left: 50%;
          transform: translateX(-50%);
        `}
      />
    </>
  );
}

export default Agenda;
