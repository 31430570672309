import React from "react";

function OrientationOverlay() {
  return (
    <div>
      <div className="orientation-background" />
      <div className="orientation-message">
        <div className="v-center">
          <span>Draai uw telefoon voor optimale weergave</span>
          <img src="/assets/rotate.svg" alt="Draai uw telefoon" />
        </div>
      </div>
    </div>
  );
}

export default OrientationOverlay;
