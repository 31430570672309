import { css } from "emotion";
import React, { HTMLProps, useContext, useEffect, useState } from "react";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
// import { FirebaseContext } from "../firebase";
import { FirebaseContext } from "../../../firebase";
import { PopupContext } from "../../pop-up";

function getRandom(arr: any[], n: number) {
  console.log("get random", arr, n);
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}
const ImageContainer = (props: Props & HTMLProps<HTMLDivElement>) => {
  const {
    element: { questionId, columns, limit, stickLeft },
    shuffle: shuffleTrigger,
  } = props;
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [widths, setWidths] = useState<string[]>();
  const [margins, setMargins] = useState<number[]>();
  const [tops, setTops] = useState<number[]>();
  const [lefts, setLefts] = useState<number[]>();
  const [images, setImages] = useState<string[]>([]);
  const firebase = useContext(FirebaseContext);
  const [answers] = useCollectionDataOnce<ImageAnswer>(
    firebase.firestore
      .collection("collected_data")
      .where("question_id", "==", questionId ? questionId : "")
  );
  const [answersLoaded, setAnswersLoaded] = useState(false);
  const [answerIndex, setAnswerIndex] = useState(0);
  const [filteredAnswers, setFilteredAnswers] = useState<ImageAnswer[]>([]);
  useEffect(() => {
    if (answers && answers.length > 1) {
      let newIndex = Math.floor(Math.random() * filteredAnswers.length);
      console.log(1, newIndex);
      while (
        newIndex === answerIndex ||
        !filteredAnswers[newIndex].answer_media_uri
      ) {
        console.log(2, newIndex);
        newIndex = Math.floor(Math.random() * filteredAnswers.length);
      }
      console.log(3, newIndex);
      console.log(filteredAnswers[newIndex]);
      setAnswerIndex(newIndex);
      setImages([filteredAnswers[newIndex].answer_media_uri]);
    }
  }, [shuffleTrigger, filteredAnswers]);
  useEffect(() => {
    if (answers && !answersLoaded) {
      const filteredAnswers = answers.filter(
        (answer) =>
          answer.answer_media_uri &&
          answer.answer_media_uri !==
            "https://media.hetgrotestraatberaad.nl/File extension was other than .jpg, .png, .gif or .mp4" &&
          !answer.hide
      );
      setFilteredAnswers(filteredAnswers);
    }
  }, [answers, answersLoaded]);

  useEffect(() => {
    if (!filteredAnswers.length) {
      return;
    }
    setImages(
      getRandom(
        filteredAnswers,
        limit
          ? limit > filteredAnswers.length
            ? filteredAnswers.length
            : limit
          : 1
      )
        .map((answer) => {
          console.log(answer);
          return answer;
        })
        .map((answer) => answer && answer.answer_media_uri)
    );
    setWidths(
      filteredAnswers.map(() =>
        columns ? `${100 / columns - 5}%` : `${15 + Math.random() * 15}%`
      )
    );
    setMargins(filteredAnswers.map(() => Math.random() * 20 - 5));
    setTops(filteredAnswers.map(() => Math.random() * 60 - 30));
    setLefts(filteredAnswers.map(() => Math.random() * 20 - 10));
    setAnswersLoaded(true);
  }, [filteredAnswers, columns]);

  useEffect(() => {
    if (!filteredAnswers.length) {
      return;
    }
    setImages(
      getRandom(
        filteredAnswers,
        limit
          ? limit > filteredAnswers.length
            ? filteredAnswers.length
            : limit
          : 1
      )
        .map((answer) => {
          console.log(answer);
          return answer;
        })
        .map((answer) => answer && answer.answer_media_uri)
    );
  }, [filteredAnswers, shuffleTrigger]);

  const popupContext = useContext(PopupContext);

  const handleImageClick = (image: string) => {
    popupContext.setContent(
      <div
        className={css`
          position: absolute;
          display: block;
          top: 20px;
          bottom: 20px;
          left: 20px;
          right: 20px;
          background-image: url(${image});
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        `}
        onClick={() => popupContext.setContent(null)}
      />
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        {widths &&
          margins &&
          tops &&
          lefts &&
          images.map((image: string, index: number) => (
            <img
              key={`image${index}`}
              style={{
                width: widths[index],
                margin: margins[index],
                position: "relative",
                top: tops[index],
                left: lefts[index],
                opacity: 0.9,
                cursor: "pointer",
              }}
              onClick={() => handleImageClick(image)}
              src={image}
              alt=""
            />
          ))}
      </div>
      {selectedImage && (
        <img
          src={selectedImage}
          style={{
            position: "fixed",
            top: 20,
            background: "rgba(0,0,0,0.5)",
            right: stickLeft ? "auto" : 20,
            left: stickLeft ? 20 : "auto",
            height: "calc(100% - 40px)",
            zIndex: 9001,
          }}
          alt=""
          onClick={() => setSelectedImage(null)}
        />
      )}
    </>
  );
};

export default ImageContainer;
