import React, { useState } from "react";
import Colophon from "./Colophon";
import { css } from "emotion";

function NavigationMenu() {
  const [showMenu, setShowMenu] = useState("hideMenu");
  const [showHamburger, setShowHamburger] = useState("hideHamburger");

  const toggleMenu = () => {
    setShowMenu((prevState: string) =>
      prevState === "hideMenu" ? "showMenu" : "hideMenu"
    );
    setShowHamburger((prevState: string) =>
      prevState === "hideHamburger" ? "showHamburger" : "hideHamburger"
    );
    setOpenItem(null);
  };

  const [openItem, setOpenItem] = useState<string | null>(null);

  const ShowHiddenLayer = () =>
    showMenu === "showMenu" ? (
      <>
        <div className="hidden" onClick={toggleMenu}></div>
      </>
    ) : null;

  const Menu = () => (
    <>
      <div className={`menu ${showMenu}`}>
        {openItem && (
          <div
            onClick={() => setOpenItem(null)}
            className={css`
              position: fixed;
              top: 90px;
              right: 40px;
              color: white;
              cursor: pointer;
            `}
          >
            <img
              className={css`
                height: 36px;
              `}
              src="/assets/icon-back.png"
              alt="back"
            />
          </div>
        )}
        <div className="scroll">
          <div
            className={css`
              padding: 0 0 64px 0;
            `}
          >
            {!openItem && (
              <>
                <div className="menuItem" onClick={() => setOpenItem("about")}>
                  <div className="boldWord">Over</div> Het Grote Straatberaad
                </div>
                <div className="menuItem">
                  <div
                    className="boldWord"
                    onClick={() => setOpenItem("colophon")}
                  >
                    Credits
                  </div>
                </div>
                <div className="menuItem">
                  <div
                    className="boldWord"
                    onClick={() => setOpenItem("inschrijven")}
                  >
                    Inschrijven
                  </div>
                </div>
                <div className="menuItem">
                  <div
                    className="boldWord"
                    onClick={() => setOpenItem("contact")}
                  >
                    Contact
                  </div>
                </div>
              </>
            )}
            {openItem === "colophon" && (
              <div
                className={css`
                  padding: 0px 32px 32px 0;
                `}
              >
                <h2>Credits</h2>
                <p>
                  Het Grote Straatberaad is onderdeel van Warming Up, een
                  campagne van Stichting Impact Makers. Meer informatie over
                  deze campagne vind je op{" "}
                  <a href="https://wearewarmingup.nl">www.wearewarmingup.nl</a>
                </p>
                <p>
                  <strong>CONCEPT EN REGIE</strong>
                  <br></br>
                  Karlijn Benthem, Vera Born en Matthea de Jong
                </p>
                <p>
                  <strong>DRAMATURGIE</strong>
                  <br></br>
                  Tomas Leijen
                </p>
                <p>
                  <strong>GELUIDSONTWERP</strong>
                  <br />
                  George Dhauw
                </p>
                <p>
                  <strong>ASSISTENTIE</strong>
                  <br></br>
                  Merijn Viergever
                </p>
                <p>
                  <strong>STEMMEN</strong>
                  <br></br>
                  Dionne Verwey en Emmanuel Boafo
                </p>
                <p>
                  <strong>PRODUCTIE</strong>
                  <br></br>
                  Mattie Boekema, Floortje Halters en Warming Up
                </p>
                <p>
                  <strong>WEB-APP</strong>
                  <br></br>
                  Fabian Hijlkema
                  <br />
                </p>
                <p>
                  <strong>WEBSITE</strong>
                  <br />
                  Luuk Schipperheyn en Ibo Ibelings (development)
                  <br />
                  Ruiter Janssen (design)
                </p>
              </div>
            )}
            {openItem === "about" && (
              <div
                className={css`
                  padding: 0px 32px 32px 0;
                `}
              >
                <h2>Over Het Grote Straatberaad</h2>
                <p>
                  Het klimaat verandert. Maar wat doen we ermee? Tijd voor een
                  ongewone vergadering voor gewone mensen. Tijdens Het Grote
                  Straatberaad ga je in gesprek met je buren en kennissen over
                  het klimaatvraagstuk en over welke toekomst je voor je ziet.
                  Video's, muziek en opdrachten helpen je op weg. Van zwerfafval
                  in de buurt tot sociale ongelijkheid, van een wormenhotel tot
                  de blinkende sterrenhemel in de lucht, het woord is aan
                  jullie!
                </p>
                <p>
                  De uitkomst is een Straatverklaring, een actieplan voor jullie
                  zelf, voor de overheid of voor het bedrijfsleven. Wij
                  verzamelen de input van alle Straatberaden op deze website.
                  Dit interactieve platform vormt uiteindelijk de eerste
                  bottom-up Klimaatverklaring van Nederland, die we overhandigen
                  aan de overheid.
                </p>
              </div>
            )}
            {openItem === "inschrijven" && (
              <div
                className={css`
                  padding: 0px 32px 32px 0;
                `}
              >
                <h2>Inschrijven</h2>
                <p>
                  Van september t/m oktober organiseren we straatberaden door
                  heel Nederland. Houdt onze <a href="/agenda">agenda</a> in de
                  gaten voor data en locaties. Wil je ook een straatberaad
                  organiseren met jouw straat, buurt, dorp, camping,
                  vriendengroep of sportclub? Laat het ons weten via{" "}
                  <a href="mailto:info@wearewarmingup.nl">
                    info@wearewarmingup.nl
                  </a>
                  .
                </p>
              </div>
            )}
            {openItem === "contact" && (
              <div
                className={css`
                  padding: 0px 32px 32px 0;
                `}
              >
                <h2>Contact</h2>
                <p>
                  Heb je vragen, wil je overleggen of heb je een fantastisch
                  idee voor Het Grote Straatberaad?
                </p>
                <p>
                  Laat het ons weten via{" "}
                  <a href="mailto:info@wearewarmingup.nl">
                    info@wearewarmingup.nl
                  </a>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <nav role="navigation">
      <div className={`menuToggle ${showHamburger}`} onClick={toggleMenu}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
      <Menu />
      <ShowHiddenLayer />

      {/* <a href="#"><li><div className="boldWord" onClick={() => setShowColophon((show) => !show)}> Colophon</div></li></a>
        {ColophonShow(showColophon)} */}
    </nav>
  );
}

export default NavigationMenu;
