import React, { useEffect, useRef, useState } from "react";
import Text from "react-svg-text";
import useMeasure from "use-measure";

const TextComponent = (props: any) => {
  const { element } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const measurement = useMeasure(containerRef);
  const [viewBoxHeight, setViewBoxHeight] = useState(0);
  useEffect(() => {
    setViewBoxHeight((measurement.height / measurement.width) * 100);
  }, [measurement]);

  return (
    <div
      ref={containerRef}
      style={{
        height: "100%",
      }}
    >
      {isFinite(viewBoxHeight) && (
        <svg
          width="100%"
          height="100%"
          viewBox={`0 0 100 ${viewBoxHeight}`}
          fill={element.css.color ? element.css.color : "#fff"}
        >
          <Text
            x={0}
            y={viewBoxHeight / 2 + (element.icon ? 1.5 : 0)}
            verticalAnchor="middle"
            textAnchor="start"
            width="80"
            height={viewBoxHeight}
            lineHeight="1.5em"
            style={{
              fontSize: "6px",
              ...element.textStyle,
            }}
          >
            {element.text}
          </Text>
        </svg>
      )}
    </div>
  );
};

export default TextComponent;
