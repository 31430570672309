import { firestore } from "firebase";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { FirebaseContext } from "../../firebase";

function Straten(props: any) {
  const history = useHistory();
  const firebase = useContext(FirebaseContext);
  const [declarations, setDeclarations] = useState<Declaration[]>([]);

  useEffect(() => {
    firebase.firestore
      .collection("events")
      .get()
      .then((events) => {
        console.log({ events: events.docs });
        const declarationsPromises = events.docs.map((event) => {
          return firebase.firestore
            .collection(`events/${event.id}/declarations`)
            .get()
            .then((results) =>
              results.docs
                .filter((result) => !result.data().hide)
                .map((result) => ({
                  id: result.id,
                  content: result.data().declaration,
                  eventDeclaration: event.data().event_declaration,
                }))
            );
        });
        // .filter((docs) => docs.length);
        Promise.all(declarationsPromises).then((declarations) => {
          setDeclarations(_.flatten(declarations));
        });
      });
  }, []);

  useEffect(() => {
    console.log({ declarations });
  }, [declarations]);
  return (
    <div className="straatverklaring">
      <div className="blue bold">STRAATVERKLARINGEN</div>
      {declarations.length === 0 && <div>Laden...</div>}
      {declarations.map((declaration, i) => {
        const { streetIndex, cityIndex } = declaration.eventDeclaration.reduce(
          (acc, item, index) => {
            if (item.phrase_function === "street") {
              acc.streetIndex = index;
            }
            if (item.phrase_function === "city") {
              acc.cityIndex = index;
            }
            return acc;
          },
          {} as { streetIndex?: number; cityIndex?: number }
        );
        if (
          typeof streetIndex === "undefined" ||
          typeof cityIndex === "undefined"
        ) {
          return null;
        }
        return (
          <div key={`declaration-${i}`}>
            <span
              className="clickable"
              // onClick={() => props.click(declaration)}
              onClick={() =>
                history.push(`/straatverklaringen/${declaration.id}`)
              }
            >
              <span className="bold">
                {declaration.content[streetIndex].phrase_text},{" "}
              </span>
              <span>&nbsp;{declaration.content[cityIndex].phrase_text}</span>
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default Straten;
