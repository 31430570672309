import React, { HTMLProps } from "react";
import { useLocation } from "react-router-dom";
import { css } from "emotion";

type Props = {
  element: any;
  onClickLink: (videoCode: string) => void;
};

const YoutubeLink = (props: Props & HTMLProps<HTMLDivElement>) => {
  //  const element = props.imageUrl
  const {
    element: { youtubeUrl },
    onClickLink,
  } = props;

  let location = useLocation().pathname.split("/");
  const isInEdit = location[location.length - 1] === "edit";

  const speakerImage = isInEdit ? (
    <img
      src="/assets/speaker.png"
      style={{ width: "100%" }}
      alt="link to YouTube"
    />
  ) : (
    <img
      onClick={() => onClickLink(youtubeUrl)}
      src="/assets/speaker.png"
      className={css`
        width: 100%;
        cursor: pointer;
      `}
      alt="link to YouTube"
    />
  );

  return <>{speakerImage}</>;
};

export default YoutubeLink;
