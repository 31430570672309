import React, {
  HTMLProps,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
// import { FirebaseContext } from "../firebase";
import { FirebaseContext } from "../../../firebase";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { css } from "emotion";
import { firestore } from "firebase";

const Video = (props: any) => {
  const [metadata, setMetadata] = React.useState<{
    videoHeight: number;
    videoWidth: number;
  }>();
  const isIOS =
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  const { playing, style, ...otherProps } = props;
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (playing) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [playing]);
  return (
    <div
      style={{
        position: "relative",
        cursor: "pointer",
        background: "rgba(0,0,0,0.5)",
        height: "100%",
        ...style,
        textAlign: "center",
      }}
      {...otherProps}
    >
      {isIOS ? (
        <video
          ref={videoRef}
          preload="metadata"
          onLoadedMetadata={(e: any) => {
            setMetadata({
              videoHeight: e.target.videoHeight,
              videoWidth: e.target.videoWidth,
            });
          }}
          controls
          {...otherProps}
          style={
            !metadata
              ? { width: "100%", height: "100%" }
              : {
                  width:
                    metadata.videoWidth > metadata.videoHeight
                      ? "100%"
                      : "auto",
                  height:
                    metadata.videoWidth < metadata.videoHeight
                      ? "100%"
                      : "auto",
                }
          }
        />
      ) : (
        <video
          ref={videoRef}
          preload="metadata"
          onLoadedMetadata={(e: any) => {
            setMetadata({
              videoHeight: e.target.videoHeight,
              videoWidth: e.target.videoWidth,
            });
          }}
          controls
          {...otherProps}
          style={
            !metadata
              ? { width: "100%", height: "100%" }
              : {
                  width:
                    metadata.videoWidth > metadata.videoHeight
                      ? "100%"
                      : "auto",
                  height:
                    metadata.videoWidth < metadata.videoHeight
                      ? "100%"
                      : "auto",
                }
          }
        />
      )}
      {!playing && !isIOS && (
        <img
          src="/assets/icon-play.png"
          alt="play video"
          className={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 25%;
            max-width: 48px;
          `}
        />
      )}
    </div>
  );
};

const VideoContainer = (
  props: { shuffle: number; playing?: boolean } & Props &
    HTMLProps<HTMLDivElement>
) => {
  const {
    element: { questionId },
    shuffle: shuffleTrigger,
    playing,
    ...otherProps
  } = props;
  const firebase = useContext(FirebaseContext);
  const [answers] = useCollectionDataOnce<VideoAnswer>(
    firebase.firestore
      .collection("collected_data")
      .where("question_id", "==", questionId ? questionId : "")
  );
  const [answerIndex, setAnswerIndex] = useState(0);
  useEffect(() => {
    if (answers && answers.length > 1) {
      let newIndex = Math.floor(Math.random() * answers.length);
      console.log(1, newIndex);
      while (newIndex === answerIndex || !answers[newIndex].answer_media_uri) {
        console.log(2, newIndex);
        newIndex = Math.floor(Math.random() * answers.length);
      }
      console.log(3, newIndex);
      console.log(answers[newIndex]);
      setAnswerIndex(newIndex);
    }
  }, [shuffleTrigger]);

  return (
    <>
      {answers && answers.length && (
        <Video
          style={{
            width: "100%",
          }}
          src={answers[answerIndex].answer_media_uri + "#t=0.1"}
          playing={playing}
          {...otherProps}
        />
      )}
    </>
  );
};

export default VideoContainer;
