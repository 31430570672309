import styled from "@emotion/styled";
import { css } from "emotion";
import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import HorizontalScroll from "react-scroll-horizontal";
import API from "../../api";
import Element from "../Elements/element";
import { VoorstellenQuery } from "../VoorstellenQuery";

const ChapterContainer = styled.div`
  height: 100%;
  position: relative;
  display: inline-block;
`;

const Background = styled.img`
  height: 100%;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
`;

const ChapterComponent = (props: RouteComponentProps<any>) => {
  const [loading, setLoading] = useState(true);
  const [elements, setElements] = useState<any[]>([]);
  const [background, setBackground] = useState<string | null>();
  const container = useRef<HTMLDivElement>(null);
  const { chapterName } = props.match.params;
  const [youtubeVideoCode, setYoutubeVideoCode] = useState<string | null>(null);
  const [playingElementIndex, setPlayingElementIndex] = useState<number | null>(
    null
  );
  const [tag, setTag] = useState<undefined | string>(undefined);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    setLoading(true);
    API.getChapter(chapterName).then((chapter: any) => {
      setElements(chapter.elements ? chapter.elements : []);
      setTag(chapter.tag);
      setBackground(chapter.background);
      setLoading(false);
    });
  }, [chapterName]);

  window.ondragstart = function () {
    return false;
  };

  const YoutubeFrame = (code: string) => (
    <div
      className={css`
        position: fixed;
        background: #000;
        bottom: 0;
        left: 0;
      `}
    >
      <div
        className={css`
          background: #000;
          display: flex;
          justify-content: flex-end;
        `}
      >
        <img
          src="/assets/icon-close.png"
          onClick={() => setYoutubeVideoCode(null)}
          alt="close video"
          className={css`
            height: 16px;
            padding: 4px;
            cursor: pointer;
          `}
        />
      </div>
      <iframe
        title={`youtube-${code}`}
        width="200"
        height="100"
        src={`https://www.youtube.com/embed/${code}?autoplay=1&controls=0&modestbranding=1&playsinline=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );

  if (loading) {
    return <div>loading...</div>;
  }

  if ("ontouchstart" in document.documentElement) {
    return (
      <>
        {!scrolled && (
          <img
            src="/assets/scroll.png"
            className={css`
              position: fixed;
              top: 50%;
              right: 16px;
              transform: translateY(-50%);
              z-index: 999;
              box-shadow: -1px 1px 2px rgb(255 255 255 / 27%);

              -webkit-animation: slide-left 1.2s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
              animation: slide-left 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
                infinite both;
              height: 11vh;
            `}
          />
        )}
        <ChapterContainer ref={container} onTouchMove={() => setScrolled(true)}>
          {background && (
            <Background src={`/assets/${background}`} alt="background" />
          )}
          {elements.map((element, index) => (
            <Element
              key={`element-${index}`}
              element={element}
              onClickYoutube={(code) => {
                setYoutubeVideoCode(code);
              }}
              onPlay={() =>
                setPlayingElementIndex(
                  index === playingElementIndex ? null : index
                )
              }
              playing={playingElementIndex === index}
            />
          ))}
        </ChapterContainer>
        {youtubeVideoCode && YoutubeFrame(youtubeVideoCode)}
      </>
    );
  } else {
    return (
      <>
        {tag && <VoorstellenQuery tag={tag} />}
        {!scrolled && (
          <img
            src="/assets/scroll.png"
            className={css`
              position: fixed;
              top: 50%;
              right: 16px;
              transform: translateY(-50%);
              z-index: 999;
              box-shadow: -1px 1px 2px rgb(255 255 255 / 27%);

              -webkit-animation: slide-top 1.2s
                cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
              animation: slide-top 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
                infinite both;
            `}
          />
        )}
        <HorizontalScroll
          onScrollStart={() => setScrolled(true)}
          reverseScroll={true}
          pageLock={false}
        >
          {[
            <ChapterContainer
              ref={container}
              key="scroll-child-0"
              onScroll={console.log}
            >
              {background && (
                <Background
                  id="background"
                  src={`/assets/${background}`}
                  alt="background"
                />
              )}
              {elements.map((element, index) => (
                <Element
                  key={`element-${index}`}
                  element={element}
                  onClickYoutube={(code) => {
                    setYoutubeVideoCode(code);
                  }}
                  onPlay={() =>
                    setPlayingElementIndex(
                      index === playingElementIndex ? null : index
                    )
                  }
                  playing={playingElementIndex === index}
                />
              ))}
            </ChapterContainer>,
          ]}
        </HorizontalScroll>
        {youtubeVideoCode && YoutubeFrame(youtubeVideoCode)}
      </>
    );
  }
};
export default ChapterComponent;
