import chapters from "./chapters";
class API {
  // private static baseUrl = "http://straatberaad-api.luuk.computer";
  private static baseUrl = "https://hetgrotestraatberaad.nl";
  // private static baseUrl = "http://localhost:3000";

  static async fetch(url: string, options?: RequestInit) {
    return fetch(url, { ...options });
  }
  static async getChapter(chapterName: string) {
    // @ts-ignore
    return Promise.resolve().then(() => chapters[chapterName]);
  }
  static saveChapter(chapterName: string, chapter: any) {
    return this.fetch(`${this.baseUrl}/chapters/${chapterName}`, {
      method: "POST",
      body: JSON.stringify(chapter),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  static async upload(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    const response = await this.fetch(`${this.baseUrl}/upload`, {
      method: "POST",
      body: formData,
    });
    if (!response.ok) {
      return;
    }
    const { path } = await response.json();
    return `${this.baseUrl}/${path}`;
  }
}

export default API;
