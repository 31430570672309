import React, {
  HTMLProps,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import Text from "react-svg-text";
import useMeasure from "use-measure";
import { FirebaseContext } from "../../../firebase";

const AgeAnswer = (props: Props & HTMLProps<HTMLDivElement>) => {
  const { element } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const measurement = useMeasure(containerRef);
  const [viewBoxHeight, setViewBoxHeight] = useState(0);
  useEffect(() => {
    setViewBoxHeight((measurement.height / measurement.width) * 100);
  }, [measurement]);
  const questionId =
    element.category === "youngest"
      ? "AG2JkF9UBB9rynuSLSvX"
      : "paOCpHWKTLOrUHKoMWXY";
  const firebase = useContext(FirebaseContext);
  const [answers, loading, error] = useCollectionDataOnce<Answer>(
    firebase.firestore
      .collection("collected_data")
      .where("question_id", "==", questionId ? questionId : "")
  );
  const [sortedAges, setSortedAges] = useState<number[]>([]);
  const [agesLoaded, setAgesLoaded] = useState(false);
  useEffect(() => {
    if (answers && answers.length && !agesLoaded) {
      setSortedAges(
        answers
          .map((answer) => parseInt(answer.answer_text))
          .sort((a, b) => {
            if (element.category === "youngest") {
              return a - b;
            }
            return b - a;
          })
      );
      setAgesLoaded(true);
    }
  }, [answers, agesLoaded]);

  return (
    <div
      ref={containerRef}
      style={{
        height: "100%",
      }}
    >
      {isFinite(viewBoxHeight) && (
        <svg
          width="100%"
          height="100%"
          viewBox={`0 0 100 ${viewBoxHeight}`}
          fill={element.css.color ? element.css.color : "#fff"}
        >
          <Text
            x={0}
            y={viewBoxHeight / 2}
            verticalAnchor="middle"
            textAnchor="start"
            width="92"
            height={viewBoxHeight}
            lineHeight="1.5em"
            style={{
              fontSize: "6px",
              ...element.textStyle,
            }}
          >
            {sortedAges &&
              sortedAges.length &&
              sortedAges[element.index ? element.index : 0]}
          </Text>
        </svg>
      )}
    </div>
  );
};

export default AgeAnswer;
