import React, { useRef, useEffect, useState, useContext } from "react";
import Text from "react-svg-text";
import useMeasure from "use-measure";
import { PopupContext } from "../../pop-up";
import { css } from "emotion";
import { GlobalStateContext } from "../../../global-state";
import { FirebaseContext } from "../../../firebase";

const ConditionPopup = ({
  firebase,
  popupContext,
  globalState,
  setRandomVoorstel,
}: {
  firebase: any;
  popupContext: any;
  globalState: any;
  setRandomVoorstel: any;
}) => {
  const [condition, setCondition] = useState("");
  return (
    <div
      className={css`
        flex: 1;
      `}
    >
      <div>Vul hier je voorwaarde in</div>
      <div>
        <textarea
          rows={3}
          value={condition}
          onChange={(e) => {
            console.log("heeeeh", {
              value: e.target.value,
              condition,
            });
            setCondition(e.target.value);
          }}
          className={css`
            padding: 16px;
            font-size: 24px;
            width: 100%;
          `}
        />
      </div>
      <div
        className={css`
          background: ${condition ? "#e6b016" : "#555555"};
          color: black;
          padding: 16px;
          display: inline-block;
          font-weight: bold;
          cursor: pointer;
        `}
        onClick={() => {
          if (!condition) {
            return;
          }
          const data = {
            [firebase.auth.currentUser?.uid!]: {
              answer_text: condition,
              answer_choice_index: 2,
            },
          };
          firebase.firestore
            .collection("votes")
            .doc(globalState.selectedVoorstel?.data()?.question_id)
            .set(data, { merge: true });
          setTimeout(
            () => setRandomVoorstel(globalState.selectedVoorstel?.id),
            100
          );
          popupContext.setContent(null);
          setCondition("");
        }}
      >
        indienen
      </div>
    </div>
  );
};

const ButtonComponent = (props: any) => {
  const { element } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const measurement = useMeasure(containerRef);
  const [viewBoxHeight, setViewBoxHeight] = useState(0);
  useEffect(() => {
    setViewBoxHeight((measurement.height / measurement.width) * 100);
  }, [measurement]);
  const popupContext = useContext(PopupContext);

  const firebase = useContext(FirebaseContext);

  const globalState = useContext(GlobalStateContext);

  const setRandomVoorstel = (previousVoorstelId?: string) => {
    const { voorstellen } = globalState;
    if (voorstellen) {
      let voorstelId = previousVoorstelId;
      let selectedVoorstel;
      while (voorstelId === previousVoorstelId) {
        const randomIndex = Math.floor(Math.random() * voorstellen.docs.length);
        selectedVoorstel = voorstellen.docs[randomIndex];
        voorstelId = selectedVoorstel.id;
      }
      globalState.setState({ selectedVoorstel });
    }
  };

  return (
    <div
      ref={containerRef}
      style={{
        height: "100%",
      }}
      onClick={() => {
        switch (element.buttonValue) {
          case "mits":
            popupContext.setContent(
              <ConditionPopup
                {...{
                  firebase,
                  popupContext,
                  globalState,
                  setRandomVoorstel,
                }}
              />,
              "Eens, maar alleen als..."
            );
            break;
          case "eens":
          case "oneens":
            const data = {
              [firebase.auth.currentUser?.uid!]: {
                answer_text: element.buttonValue,
                answer_choice_index: { eens: 0, oneens: 1 }[
                  element.buttonValue as "eens" | "oneens"
                ],
              },
            };
            firebase.firestore
              .collection("votes")
              .doc(globalState.selectedVoorstel?.data()?.question_id)
              .set(data, { merge: true });
            setTimeout(
              () => setRandomVoorstel(globalState.selectedVoorstel?.id),
              100
            );
            break;
        }
      }}
    >
      {isFinite(viewBoxHeight) && (
        <svg
          width="100%"
          height="100%"
          viewBox={`0 0 100 ${viewBoxHeight}`}
          fill={element.css.color ? element.css.color : "#fff"}
        >
          <Text
            x={0}
            y={viewBoxHeight / 2}
            verticalAnchor="middle"
            textAnchor="start"
            width="92"
            height={viewBoxHeight}
            lineHeight="1.5em"
            style={{
              fontSize: "6px",
              ...element.textStyle,
            }}
          >
            {element.text}
          </Text>
        </svg>
      )}
    </div>
  );
};

export default ButtonComponent;
