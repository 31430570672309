import React, { HTMLProps, useContext, useState, useEffect } from "react";
import { FirebaseContext } from "../../../firebase";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { css } from "emotion";
import { mq } from "../../../utils/media-query";

const TextAnswer = (
  props: { shuffle: number } & Props & HTMLProps<HTMLDivElement>
) => {
  const {
    element: { questionId },
    shuffle: shuffleTrigger,
  } = props;
  const firebase = useContext(FirebaseContext);
  const [answers, loading, error] = useCollectionDataOnce<Answer>(
    firebase.firestore
      .collection("collected_data")
      .where("question_id", "==", questionId ? questionId : "")
  );
  const [answerIndex, setAnswerIndex] = useState(0);
  useEffect(() => {
    if (answers && answers.length > 1) {
      let newIndex = Math.floor(Math.random() * answers.length);
      while (newIndex === answerIndex || !answers[newIndex].answer_text) {
        newIndex = Math.floor(Math.random() * answers.length);
      }
      console.log(answers[newIndex]);
      setAnswerIndex(newIndex);
    }
  }, [shuffleTrigger]);
  const [answersLoaded, setAnswersLoaded] = useState(false);
  useEffect(() => {
    if (!answersLoaded && answers && answers.length > 1) {
      let newIndex = Math.floor(Math.random() * answers.length);
      while (newIndex === answerIndex || !answers[newIndex].answer_text) {
        newIndex = Math.floor(Math.random() * answers.length);
      }
      setAnswerIndex(newIndex);
      setAnswersLoaded(true);
    }
  }, [answers]);
  /*  console.log(value)*/
  return (
    <div>
      {error && <div>error: {JSON.stringify(error)}</div>}
      {answers && answers.length && (
        <div
          className={css`
            font-size: 24px;
            ${mq()} {
              font-size: 16px;
            }
          `}
        >
          {answers[answerIndex].answer_text}
        </div>
      )}
      {loading && <div>loading</div>}
    </div>
  );
};

export default TextAnswer;
