import { firestore } from "firebase";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../../firebase";

function Straat({ declarationId }: { declarationId: string }) {
  const firebase = useContext(FirebaseContext);
  const [declaration, setDeclaration] = useState<Declaration>();

  useEffect(() => {
    firebase.firestore
      .collection("events")
      .get()
      .then((events) => {
        const declarationsPromises = events.docs.map((event) => {
          return firebase.firestore
            .collection(`events/${event.id}/declarations`)
            .get()
            .then((results) =>
              results.docs
                .filter((result) => result.id === declarationId)
                .map((result) => ({
                  id: result.id,
                  content: result.data().declaration,
                  eventDeclaration: event.data().event_declaration,
                }))
            );
        });
        // .filter((docs) => docs.length);
        Promise.all(declarationsPromises).then((declarations) => {
          setDeclaration(_.flatten(declarations)[0]);
        });
      });
  }, []);

  if (!declaration) {
    return null;
  }

  const { streetIndex, cityIndex } = declaration.eventDeclaration.reduce(
    (acc, item, index) => {
      if (item.phrase_function === "street") {
        acc.streetIndex = index;
      }
      if (item.phrase_function === "city") {
        acc.cityIndex = index;
      }
      return acc;
    },
    {} as { streetIndex?: number; cityIndex?: number }
  );
  if (typeof streetIndex === "undefined" || typeof cityIndex === "undefined") {
    return null;
  }
  return (
    <div className="verklaring-container">
      {declaration && (
        <>
          <span className="big verklaring">{`De Straatverklaring van ${declaration.content[streetIndex].phrase_text}`}</span>
          {declaration.content.map((declarationItem, i) => (
            <span
              key={`declarationItem-${i}`}
              className={`verklaring verklaring--${declarationItem.phrase_type}`}
            >
              {declarationItem.phrase_text}{" "}
            </span>
          ))}{" "}
        </>
      )}
    </div>
  );
}

export default Straat;
