import React, {useState,useRef,useEffect} from 'react'
import {Pie} from 'react-chartjs-2'


const colorScheme = [
    'rgba(75,168,245,1)',
    'rgba(64,116,242,1)',
    'rgba(51,39,158,1)'
]

const pieChartData = [
  {
    question: "Lukt het ons om in 2030 de CO2 uitstoot met de helft te verminderen? ",
    answers: [
      {
       ans :'Het ligt aan het bedrijfsleven',
       amount : 146
      },
      {
       ans :'Het ligt aan de politiek',
       amount : 292
      },

      {
       ans :'Dat is onhaalbaar',
       amount : 292
      },
    ]
  },

  {
    question: "Ben je het eens of oneens met een nieuwe stemgerechtige leeftijd van 12 tot 50 jaar?",
    answers:[
      {
        ans: 'Eens',
        amount: 60
      },
      {
        ans: 'Oneens',
        amount: 40
      }
    ]
    }
]

const updateQuestionData = (selectedQuestion:number) => {
  return {
    labels : pieChartData[selectedQuestion].answers.map((an) => an.ans),
    datasets:[{
      data : pieChartData[selectedQuestion].answers.map((an) => an.amount),
      backgroundColor: colorScheme.map(( color ) => color )
    }],
    question: pieChartData[selectedQuestion].question
    }
}


const PieChartTest = () => {
  let [selectedQuestion, setSelectedQuestion] = useState(0);
  const selectedQuestionRef = useRef<HTMLSelectElement>(null);
  const [questionData, setQuestionData] = useState(updateQuestionData(selectedQuestion))

  useEffect(() =>{
    setQuestionData(updateQuestionData(selectedQuestion))
    },[selectedQuestion])

  function questionSelector(){
    const options = pieChartData.map((d,i)=>{
      return(<option value={i}>{d.question}</option>)
    })
  
  function selectOtherQuestion(){
    if(!selectedQuestionRef.current){
      return
    }else{
     setSelectedQuestion(parseInt(selectedQuestionRef.current.value))
    }
  }

    return (
      <div>
        <select 
          style = {{
              margin: "auto",
          }}
          onChange = {selectOtherQuestion}
          ref={selectedQuestionRef}>
          {options}
       </select>
      </div>
    )
    }

  return (
    <div style = {{width:'700px' }}>
      
      <h2> {questionData.question} </h2>
      
      <Pie data = {questionData} />

        {questionSelector()}

    </div>
  )
}

export default PieChartTest
export {colorScheme,updateQuestionData}
