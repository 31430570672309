export default {
  background: "panorama_economie_leeg.png",
  tag: "Voor de politiek",
  elements: [
    {
      type: "question",
      position: {
        x: 1,
        y: 44,
      },
      size: {
        width: 6,
        height: 30,
      },
      text: "Deze voorstellen maakten we voor onszelf. Stemmen kan door hiernaast op een van de opties te klikken.",
      css: {
        color: "rgb(227, 173, 22)",
      },
      dimensions: [1, 1],
      textStyle: {
        "font-size": "9px",
        "font-weight": "normal",
      },
      className: "questionLabel",
    },
    {
      type: "icon",
      icon: "info",
      position: {
        x: 1,
        y: 40,
      },
      size: {
        width: 6,
        height: 4,
      },
      css: {
        color: "rgb(227, 173, 22)",
        paddingTop: "2vh",
      },
      dimensions: [1, 1],
      className: "questionLabel",
    },
    {
      type: "questionSelector",
      position: {
        x: 8,
        y: 40,
      },
      size: {
        width: 16,
        height: 40,
      },
    },
    {
      type: "button",
      // left: 22.5%;
      // top: 20%;
      // width: 8.01085%;
      buttonValue: "eens",
      position: {
        x: 24.5,
        y: 40,
      },
      size: {
        width: 6,
        height: 8,
      },
      text: "Eens",
      css: {
        color: "rgb(227, 173, 22)",
      },
      dimensions: [1, 1],
      textStyle: {
        "font-size": "14px",
        "text-align": "center",
        "line-height": "0.5",
      },
      className: "voteButton",
    },
    {
      type: "button",
      buttonValue: "oneens",
      position: {
        x: 24.5,
        y: 49,
      },
      size: {
        width: 6,
        height: 8,
      },
      text: "Oneens",
      css: {
        color: "rgb(227, 173, 22)",
      },
      dimensions: [1, 1],
      textStyle: {
        "font-size": "14px",
        "text-align": "center",
        "line-height": "0.5",
      },
      className: "voteButton",
    },
    {
      type: "button",
      buttonValue: "mits",
      position: {
        x: 24.5,
        y: 58,
      },
      size: {
        width: 6,
        height: 16,
      },
      text: "Eens, maar alleen als",
      css: {
        color: "rgb(227, 173, 22)",
      },
      dimensions: [1, 1],
      textStyle: {
        "font-size": "14px",
        "text-align": "center",
        "line-height": "0.5",
      },
      className: "voteButton",
    },
    {
      type: "questionSelector",
      position: {
        x: 56,
        y: 10,
      },
      size: {
        width: 14,
        height: 45,
      },
    },
    {
      type: "graph",
      position: {
        x: 46,
        y: 32,
      },
      questionId: "rVXG3xg5QT1Mx5Ip1Xp2",
      size: {
        width: 15,
        height: 51,
      },
      text: "",
      css: {},
      graphBase: {
        question: "",
        answers: [
          {
            ans: "",
            amount: 0,
          },
        ],
        questionId: "",
      },
      dimensions: [4, 2],
      colorScheme: 0,
      labelStyle: {
        color: "#ffffff",
      },
    },
    {
      type: "graph",
      position: {
        x: 49,
        y: 32,
      },
      questionId: "rVXG3xg5QT1Mx5Ip1Xp2",
      size: {
        width: 12,
        height: 51,
      },
      text: "",
      css: {},
      graphBase: {
        question: "",
        answers: [
          {
            ans: "",
            amount: 0,
          },
        ],
        questionId: "",
      },
      dimensions: [4, 2],
      colorScheme: 0,
      labelStyle: {
        color: "#ffffff",
      },
    },
    {
      type: "question",
      position: {
        x: 40,
        y: 82,
      },
      size: {
        width: 13,
        height: 18,
      },
      text: "Zo is er gestemd op de voorstellen",
      css: {
        color: "rgb(227, 173, 22)",
      },
      dimensions: [1, 1],
      textStyle: {
        "font-size": "5px",
        "font-weight": "normal",
      },
      className: "questionLabel",
    },
    {
      type: "icon",
      icon: "info",
      position: {
        x: 40,
        y: 78,
      },
      size: {
        width: 13,
        height: 4,
      },
      css: {
        color: "rgb(227, 173, 22)",
        paddingTop: "2vh",
      },
      dimensions: [1, 1],
      className: "questionLabel",
    },
    {
      type: "text",
      position: {
        x: 71,
        y: 82.60000000000001,
      },
      size: {
        width: 9,
        height: 15,
      },
      text: "Dit zijn onze werkplannen",
      css: {
        color: "rgb(227, 173, 22)",
      },
      dimensions: [1, 1],
      textStyle: {
        "font-size": "7px",
        "font-weight": "normal",
      },
      className: "questionLabel",
    },
    {
      type: "icon",
      icon: "info",
      position: {
        x: 71,
        y: 78.60000000000001,
      },
      size: {
        width: 9,
        height: 4,
      },
      css: {
        color: "rgb(227, 173, 22)",
        paddingTop: "2vh",
      },
      dimensions: [1, 1],
      className: "questionLabel",
    },
    {
      type: "image-container",
      // left: 79.1%;
      // top: 35.6%;
      // width: 11.5%;
      // height: 40.2%;
      position: {
        x: 79.1,
        y: 37.6,
      },
      questionId: "xvkyqCrchwxJ3V6LWeDA",
      size: {
        width: 11.5,
        height: 45.2,
      },
      text: "",
      css: {},
      columns: 4,
      limit: 6,
    },
  ],
};
