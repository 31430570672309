export default {
  background: "panorama_economie_leeg.png",
  elements: [
    {
      type: "question",
      position: { x: 8.6, y: 19.80000000000001 },
      size: { width: 5.9, height: 27.59999999999998 },
      text: "Minder grond voor landbouw, meer voor duurzame woningen. Oproep aan de politiek en het bedrijfsleven / wooncoorporaties.",
      css: { color: "rgb(227, 173, 22)" },
      dimensions: [1, 1],
      textStyle: { "font-size": "7px" },
      className: "questionLabel",
    },
    {
      type: "question",
      position: {
        x: 8.6,
        y: 54,
      },
      size: {
        width: 5.9,
        height: 10.19999999999999,
      },
      text: "Eens",
      css: {
        color: "rgb(227, 173, 22)",
      },
      dimensions: [1, 1],
      textStyle: {
        "font-size": "19px",
        "text-align": "center",
      },
      className: "voteButton",
    },
    {
      type: "question",
      position: {
        x: 8.6,
        y: 66,
      },
      size: {
        width: 5.9,
        height: 10.19999999999999,
      },
      text: "Oneens",
      css: {
        color: "rgb(227, 173, 22)",
      },
      dimensions: [1, 1],
      textStyle: {
        "font-size": "19px",
        "text-align": "center",
      },
      className: "voteButton",
    },
    {
      type: "question",
      position: { x: 26.365727429557218, y: 80.2 },
      size: { width: 4.8212290502793316, height: 14.800000000000011 },
      text: "Voorstellen met de meeste stemmen",
      css: { color: "rgb(227, 173, 22)" },
      dimensions: [1, 1],
      textStyle: { "font-size": "9px" },
      className: "questionLabel",
    },
    {
      type: "graph",
      position: { x: 36.026451983898795, y: 34.2 },
      size: { width: 5.424581005586592, height: 14.199999999999996 },
      text: "",
      css: {},
      dimensions: [4, 3],
      textStyle: { "font-size": "6px" },
      questionId: "DmmqXgtHfeHklUCWonxt",
      colorScheme: 0,
      labelStyle: {
        color: "#000000",
        marginBottom: "20px",
        containerBackground: "#ffffff",
        containerPadding: "8px",
      },
    },
    {
      type: "question",
      position: { x: 53.82403680276021, y: 72.2 },
      size: { width: 5, height: 25 },
      text: "Hoe belonen we co2 uitstoot?",
      css: { color: "rgb(227, 173, 22)" },
      dimensions: [1, 1],
      textStyle: { "font-size": "8px" },
      className: "questionLabel",
    },
    {
      type: "text-answer",
      position: { x: 61.328349626221964, y: 39 },
      size: { width: 8.683151236342738, height: 41.800000000000004 },
      text: "",
      css: {
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      dimensions: [1, 1],
      textStyle: { fontSize: "6px", fontWeight: "bold" },
      questionId: "NKd5LaCyYllDQeOidpPU",
      shuffle: { x: 62.1334100057504, y: 18.6, width: 6.78315123634274 },
    },
    {
      type: "question",
      position: { x: 75.30189764232318, y: 73.4 },
      size: { width: 5, height: 25 },
      text: "Hoe bestraffen we co2 uitstoot?",
      css: { color: "rgb(227, 173, 22)" },
      dimensions: [1, 1],
      textStyle: { "font-size": "8px" },
      className: "questionLabel",
    },
    {
      type: "text-answer",
      position: { x: 84.15756181713628, y: 36 },
      size: { width: 8.683151236342738, height: 41.800000000000004 },
      text: "",
      css: {
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      dimensions: [1, 1],
      textStyle: { fontSize: "6px", fontWeight: "bold" },
      questionId: "e9XkFyptl7hTm1a6SVuc",
      shuffle: { x: 85.1334100057504, y: 19.6, width: 6.78315123634274 },
    },
  ],
};
