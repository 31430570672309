import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Observer from "@researchgate/react-intersection-observer";
import { css } from "emotion";
import React, { HTMLProps, useState } from "react";
import AgeAnswer from "./innerElements/AgeAnswer";
import ButtonComponent from "./innerElements/ButtonComponenet";
import Graph from "./innerElements/Graph";
import Image from "./innerElements/Image";
import ImageContainer from "./innerElements/ImageContainer";
import QuestionSelector from "./innerElements/QuestionSelector";
import TextAnswer from "./innerElements/TextAnswer";
import TextComponent from "./innerElements/TextComponent";
import TextQuestion from "./innerElements/TextQuestion";
import VideoElement from "./innerElements/VideoComponent";
import VideoContainer from "./innerElements/VideoContainer";
import VoorstelComponent from "./innerElements/VoorstelComponent";
import YoutubeLink from "./innerElements/YoutubeLink";

type ElementProps = Props & {
  onClickYoutube: (videoCode: string) => void;
  onPlay: () => void;
  playing?: boolean;
};

const Element = (props: ElementProps & HTMLProps<HTMLDivElement>) => {
  const { element, style, onClickYoutube, onPlay, playing, ...otherProps } =
    props;
  const [allowedToDraw, setAllowedToDraw] = useState(true);

  const [numberOfChanges, setNumberOfChanges] = useState(0);
  const [loadingIn, setLoadingIn] = useState(true);

  function handleIntersection(event: any, unobserve: any) {
    if (loadingIn) {
      setLoadingIn(false);
    } else {
      if (numberOfChanges <= 1) {
        // setAllowedToDraw(() => event.isIntersecting);
        setNumberOfChanges((prevState) => (prevState += 1));
      }
    }
  }

  const options = {
    onChange: handleIntersection,
    root: "window",
    rootMargin: "0% 0%",
  };

  const [shuffle, setShuffle] = useState(0);

  const { questionSelector } = element;

  return (
    <>
      {element.shuffle && (
        <img
          src={`/assets/${
            element.shuffle.image
              ? element.shuffle.image
              : "next-plan-button.png"
          }`}
          className={css`
            position: absolute;
            top: ${element.shuffle.y}%;
            left: ${element.shuffle.x}%;
            width: ${element.shuffle.width}%;
            cursor: pointer;
          `}
          onClick={() => {
            setShuffle(Math.random());
          }}
          alt=""
        />
      )}

      <div
        className={`${element.className ? element.className : ""} ${css`
          width: 20px;
          height: 20px;
          /* background: #000; */
          position: absolute;
        `}`}
        style={{
          left: `${element.position.x}%`,
          top: `${element.position.y}%`,
          width: `${element.size.width}%`,
          height: `${element.size.height}%`,
          ...(element.css ? element.css : {}),
          ...style,
        }}
        {...otherProps}
      >
        {element.type === "icon" && (
          <FontAwesomeIcon
            className={css`
              font-size: 4vh;
              display: block;
            `}
            icon={element.icon === "info" ? faInfoCircle : faInfoCircle}
          />
        )}
        {element.type === "graph" && (
          <div>
            <Observer {...options}>
              <div></div>
            </Observer>
            {allowedToDraw ? <Graph element={element} /> : null}
          </div>
        )}
        {element.type === "image-container" && (
          <ImageContainer element={element} shuffle={shuffle} />
        )}
        {element.type === "image" && <Image element={element} />}
        {element.type === "question-text" && <TextQuestion element={element} />}
        {element.type === "youtube-link" && (
          <YoutubeLink element={element} onClickLink={onClickYoutube} />
        )}
        {element.type === "video" && <VideoElement element={element} />}
        {element.type === "text-answer" && (
          <TextAnswer element={element} shuffle={shuffle} />
        )}
        {element.type === "video-container" && (
          <VideoContainer
            element={element}
            shuffle={shuffle}
            onClick={onPlay}
            playing={playing}
          />
        )}
        {element.type === "age" && <AgeAnswer element={element} />}
        {(element.type === "text" ||
          element.type === "question" ||
          !element.type) && <TextComponent element={element} />}
        {element.type === "voorstel" && <VoorstelComponent element={element} />}
        {element.type === "button" && <ButtonComponent element={element} />}
        {otherProps.children}
      </div>
      {element.type === "questionSelector" && (
        <QuestionSelector element={element} />
      )}
    </>
  );
};

export default Element;
