export default {
  background: "panorama_introductie_leeg.png",
  elements: [
    {
      type: "graph",
      position: { x: 14.681952662721892, y: 48 },
      questionId: "SwOkNg9ZpNIRenFnaIAy",
      size: { width: 8.579881656804735, height: 32.400000000000006 },
      text: "",
      css: {},
      graphBase: {
        question: "",
        answers: [{ ans: "", amount: 0 }],
        questionId: "",
      },
      dimensions: [2, 1],
      colorScheme: 1,
    },
    {
      type: "text",
      position: { x: 35.53253636728866, y: 39.2 },
      size: { width: 6.252312245652973, height: 8 },
      text: "1.",
      css: { color: "#000", fontWeight: "1000" },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "text",
      position: { x: 46.438114280573664, y: 37.6 },
      size: { width: 6.252312245652973, height: 8 },
      text: "2.",
      css: { color: "#000", fontWeight: "1000" },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "text",
      position: { x: 56.870966666811185, y: 38.800000000000004 },
      size: { width: 6.252312245652973, height: 8 },
      text: "3.",
      css: { color: "#000", fontWeight: "1000" },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "youtube-link",
      position: { x: 36.42043869803228, y: 61.8 },
      size: { width: 3.683150471734237, height: 3.8000000000000043 },
      text: "",
      css: {},
      dimensions: [1, 1],
      textStyle: { fontSize: "6px" },
      youtubeUrl: "90WD_ats6eE",
    },
    {
      type: "text",
      position: { x: 35.49554043684101, y: 54.800000000000004 },
      size: { width: 6.104328523862371, height: 9.600000000000001 },
      text: "Bob Dylan",
      css: { color: "#000" },
      dimensions: [1, 1],
      textStyle: { fontSize: "10px" },
    },
    {
      type: "text",
      position: { x: 35.45854450639336, y: 46.2 },
      size: { width: 6.104328523862371, height: 9.600000000000001 },
      text: "The Times Are-a-Changin'",
      css: { color: "#000" },
      dimensions: [1, 1],
      textStyle: { fontSize: "10px" },
    },
    {
      type: "youtube-link",
      position: { x: 47.001274806060394, y: 61.8000000000001 },
      size: { width: 3.683150471734237, height: 3.8000000000000043 },
      text: "",
      css: {},
      dimensions: [1, 1],
      textStyle: { fontSize: "6px" },
      youtubeUrl: "Yga6O3WhRFY",
    },
    {
      type: "text",
      position: { x: 46.39732364068859, y: 53.6 },
      size: { width: 6.104328523862371, height: 9.600000000000001 },
      text: "Ramses Shaffy",
      css: { color: "#000" },
      dimensions: [1, 1],
      textStyle: { fontSize: "10px" },
    },
    {
      type: "text",
      position: { x: 46.44633584934563, y: 45.2 },
      size: { width: 6.104328523862371, height: 9.600000000000001 },
      text: "We Zullen Doorgaan",
      css: { color: "#000" },
      dimensions: [1, 1],
      textStyle: { fontSize: "10px" },
    },
    {
      type: "youtube-link",
      position: { x: 57.545114983640865, y: 61.8 },
      size: { width: 3.683150471734237, height: 3.8000000000000043 },
      text: "",
      css: {},
      dimensions: [1, 1],
      textStyle: { fontSize: "6px" },
      youtubeUrl: "JocAXINz-YE",
    },
    {
      type: "text",
      position: { x: 56.8915194597149, y: 52.2 },
      size: { width: 6.104328523862371, height: 9.600000000000001 },
      text: "Kendrick Lamar",
      css: { color: "#000" },
      dimensions: [1, 1],
      textStyle: { fontSize: "10px" },
    },
    {
      type: "text",
      position: { x: 56.87918823558314, y: 46.2 },
      size: { width: 6.104328523862371, height: 9.600000000000001 },
      text: "Alright",
      css: { color: "#000" },
      dimensions: [1, 1],
      textStyle: { fontSize: "10px" },
    },
    {
      type: "age",
      category: "oldest",
      index: 0,
      position: { x: 91.96358668319923, y: 60.6 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "55",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "age",
      category: "oldest",
      index: 1,
      position: { x: 94.40531809274417, y: 58.199999999999996 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "61",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "age",
      category: "youngest",
      index: 0,
      position: { x: 80.12488893995098, y: 31 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "18",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "age",
      category: "oldest",
      index: 2,
      position: { x: 93.06524076980438, y: 49.4 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "57",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "age",
      category: "youngest",
      index: 1,
      position: { x: 83.23254709755364, y: 48.199999999999996 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "25",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "age",
      category: "youngest",
      index: 2,
      position: { x: 81.71571394919997, y: 55.00000000000001 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "18",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "age",
      category: "youngest",
      index: 3,
      position: { x: 79.52473022596421, y: 51.6 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "8",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "age",
      category: "youngest",
      index: 4,
      position: { x: 78.86702730473086, y: 39.4 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "12",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "age",
      category: "youngest",
      index: 5,
      position: { x: 81.0867831315899, y: 40.400000000000006 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "22",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "age",
      category: "youngest",
      index: 6,
      position: { x: 84.23950233247779, y: 37.6 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "12",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "age",
      category: "youngest",
      index: 7,
      position: { x: 82.37341687043332, y: 29.599999999999998 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "19",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "age",
      category: "oldest",
      index: 3,
      position: { x: 90.54951749936413, y: 52 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "56",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "age",
      category: "oldest",
      index: 4,
      position: { x: 92.28832623040371, y: 70.8 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "51",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "age",
      category: "oldest",
      index: 5,
      position: { x: 92.39931402174666, y: 78.8 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "57",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "age",
      category: "oldest",
      index: 6,
      position: { x: 94.43409019636745, y: 70 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "49",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "age",
      category: "oldest",
      index: 7,
      position: { x: 90.43852970802118, y: 71.39999999999999 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "61",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "age",
      category: "oldest",
      index: 8,
      position: { x: 89.40264365548696, y: 62 },
      size: { width: 7.658157602663707, height: 8.399999999999999 },
      text: "60",
      css: { fontWeight: 800 },
      dimensions: [1, 1],
      textStyle: { fontSize: "12px" },
    },
    {
      type: "question",
      position: { x: 0.4807692307692308, y: 78 },
      size: { width: 7.359467455621302, height: 19.200000000000017 },
      text: "Klimaat optimisten/pessimisten",
      css: { color: "rgb(227, 173, 22)" },
      dimensions: [1, 1],
      textStyle: { fontSize: "8px" },
      className: "questionLabel",
    },
    {
      type: "question",
      position: { x: 33.099112426035504, y: 79.4 },
      size: { width: 7.988165680473372, height: 18 },
      text: "De underscore voor de tijd waarin we leven",
      css: { color: "rgb(227, 173, 22)" },
      dimensions: [1, 1],
      textStyle: { fontSize: "8px" },
      className: "questionLabel",
    },
    {
      type: "question",
      position: { x: 66.19822485207101, y: 74.4 },
      size: { width: 8.247041420118336, height: 23.19999999999999 },
      text: "De leeftijd van de jongste en oudste deelnemer aan Het Grote Straatberaad",
      css: { color: "rgb(227, 173, 22)" },
      dimensions: [1, 1],
      textStyle: { fontSize: "8px" },
      className: "questionLabel",
    },
    {
      type: "text",
      position: {
        x: 79.5,
        y: 7,
      },
      size: {
        width: 4.4,
        height: 15,
      },
      text: "Jongste deelnemers",
      css: {
        background: "#ffffff",
        color: "#000000",
        padding: "16px 32px",
        fontWeight: "bold",
      },
      dimensions: [1, 1],
      textStyle: {
        fontSize: "14px",
      },
    },
    {
      type: "text",
      position: {
        x: 90.5,
        y: 27,
      },
      size: {
        width: 4.4,
        height: 15,
      },
      text: "Oudste deelnemers",
      css: {
        background: "#ffffff",
        color: "#000000",
        padding: "16px 32px",
        fontWeight: "bold",
      },
      dimensions: [1, 1],
      textStyle: {
        fontSize: "14px",
      },
    },
  ],
};
