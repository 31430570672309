import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import ImageMapper from "react-image-mapper";
import "./landingPage.css";
import { css } from "emotion";
import { PopupContext } from "../pop-up";

const Bordjes = (props: RouteComponentProps<any>) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [windowAspectRatio, setWindowAspectRatio] = useState(
    window.innerWidth / window.innerHeight
  );
  const onWindowResize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    setWindowAspectRatio(window.innerWidth / window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => window.removeEventListener("resize", onWindowResize);
  }, []);

  const imageAspectRatio = 1243 / 702;
  const [imageSize, setImageSize] = useState<{
    width: number;
    height: number;
  }>({
    height:
      windowAspectRatio < imageAspectRatio
        ? windowSize.height
        : windowSize.width / imageAspectRatio,
    width:
      windowAspectRatio < imageAspectRatio
        ? windowSize.height * imageAspectRatio
        : windowSize.width,
  });
  useEffect(() => {
    setImageSize({
      height:
        windowAspectRatio < imageAspectRatio
          ? windowSize.height
          : windowSize.width / imageAspectRatio,
      width:
        windowAspectRatio < imageAspectRatio
          ? windowSize.height * imageAspectRatio
          : windowSize.width,
    });
    window.scrollTo(0, (imageSize.height - windowSize.height) / 2);
  }, [windowSize, windowAspectRatio]);

  const history = useHistory();

  const popupContext = useContext(PopupContext);

  const signClicked = (area: any) => history.push(`chapter/${area.name}`);
  // const signClicked = (area: any) =>
  //   popupContext.setContent(
  //     <div>
  //       Aan dit gedeelte van de site wordt op dit moment gewerkt. Binnenkort kan
  //       je hier de resultaten van de verschillende straatberaden bekijken!
  //     </div>,
  //     "Hier wordt gewerkt"
  //   );

  const MAP = {
    name: "my-map",
    areas: [
      {
        name: "bedrijfsleven",
        shape: "poly",
        coords: [500, 375, 800, 395, 800, 465, 500, 445],
      },
      {
        name: "politiek",
        shape: "poly",
        coords: [500, 275, 800, 295, 800, 375, 500, 355],
      },
      {
        name: "onszelf",
        shape: "poly",
        coords: [500, 175, 800, 195, 800, 275, 500, 255],
      },
      {
        name: "introductie",
        shape: "poly",
        coords: [500, 75, 800, 95, 800, 180, 500, 160],
      },
    ],
  };

  const frontLayer = (
    <div className="frontImage">
      <ImageMapper
        src={`/assets/bordjes_front.png`}
        map={MAP}
        height={imageSize.height}
        width={imageSize.width}
        imgWidth={1243}
        fillColor="rgba(0,0,0,0)"
        strokeColor="rgba(0,0,0,0)"
        onClick={(area: any) => signClicked(area)}
      />
    </div>
  );

  const backgroundLayer = (
    <img
      className="backgroundImage01"
      src={`/assets/bordjes_bg.png`}
      alt="background layer"
      height={imageSize.height}
      width={imageSize.width}
    />
  );
  return (
    <div
      className={css`
        position: absolute;
        top: 0;
        bottom: 0;
        height: ${imageSize.height}px;
        width: ${imageSize.width}px;
        overflow: hidden;
      `}
    >
      {backgroundLayer}
      {frontLayer}
    </div>
  );
};

export default Bordjes;
