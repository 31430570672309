import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Route, Link, Switch } from "react-router-dom";
import Bordjes from "./components/LandingPage/Borden";
import ChapterComponent from "./components/Chapter/chapter";
import ChapterEditorComponent from "./components/Chapter/chapter-editor";
import Firebase, { FirebaseContext } from "./firebase";
import LandingPage from "./components/LandingPage/landingPage";
import pieChartTest from "./tests/pieChartTest";
import QuestionTest from "./tests/QuestionTest";
import VideoTest from "./tests/VideoTest";
import NavigationMenu from "./components/Navigation/NavigationMenu";
import Agenda from "./components/LandingPage/Agenda";
import { css } from "emotion";
import FadeLoader from "react-spinners/FadeLoader";
import useSound from "use-sound";
import PDFTest from "./tests/PDFTest";
import StraatverklaringPDF from "./components/Straatverklaringen/StraatverklaringPDF";
import PopUp, { PopupContext } from "./components/pop-up";
import { GlobalStateContext } from "./global-state";

const checkImage = (path: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(path);
    img.onerror = () => reject();
    img.src = path;
  });
const paths = [
  "/assets/agenda.png",
  "/assets/background_01.png",
  "/assets/background_02.png",
  "/assets/background_03.png",
  "/assets/background_04.png",
  "/assets/background_05.png",
  "/assets/bordjes_bg.png",
  "/assets/bordjes_front.png",
  "/assets/icon-back.png",
  "/assets/icon-close.png",
  "/assets/icon-play.png",
  "/assets/logo.png",
  "/assets/map-marker.svg",
  "/assets/next-plan-button.png",
  "/assets/panorama_democratie_leeg.png",
  "/assets/panorama_economie_leeg.png",
  "/assets/panorama_gezondheid_leeg.png",
  "/assets/panorama_introductie_leeg.png",
  "/assets/panorama_mobiliteit_leeg.png",
  "/assets/rotate.svg",
  "/assets/small/background_01.png",
  "/assets/small/background_02.png",
  "/assets/small/background_03.png",
  "/assets/small/background_04.png",
  "/assets/speaker.png",
  "/assets/speaker.svg ",
];

const Content = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Promise.all(paths.map(checkImage)).then(() => setLoading(false));
  }, []);

  const [playing, setPlaying] = useState(false);

  const [play, { stop }] = useSound("/assets/luchtalarm.mp3", { volume: 1.0 });
  useEffect(() => {
    if (playing) {
      play();
    } else {
      stop();
    }
  }, [playing, play, stop]);

  if (loading) {
    return (
      <div
        className={css`
          position: fixed;
          background: black;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #e6b016;
        `}
      >
        <img src="/assets/logo.png" className="logo" alt="logo straatberaad" />
        <FadeLoader color="#e6b016" />
      </div>
    );
  }

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/pdfTest" component={PDFTest} exact></Route>
          <Route
            path="/straatverklaringen/:declarationId/pdf"
            component={StraatverklaringPDF}
            exact
          ></Route>
          <>
            <NavigationMenu />
            <Link to="/">
              <img
                src="/assets/logo.png"
                className="logo"
                alt="logo straatberaad"
              />
            </Link>

            <Route
              path="/"
              render={(props) => (
                <LandingPage
                  {...props}
                  onPlay={() => setPlaying(!playing)}
                  preLaunch={preLaunch}
                />
              )}
              exact
            ></Route>
            <Route
              path="/straatverklaringen"
              render={(props) => (
                <LandingPage
                  {...props}
                  onPlay={() => setPlaying(!playing)}
                  preLaunch={preLaunch}
                />
              )}
              exact
            ></Route>
            <Route
              path="/straatverklaringen/:declarationId"
              render={(props) => (
                <LandingPage
                  {...props}
                  onPlay={() => setPlaying(!playing)}
                  preLaunch={preLaunch}
                />
              )}
              exact
            ></Route>
            <Route path="/bordjes" component={Bordjes}></Route>
            <Route path="/agenda" component={Agenda} exact></Route>

            <Route
              path="/chapter/:chapterName"
              component={ChapterComponent}
              exact
            ></Route>

            <Route
              path="/chapter/:chapterName/edit"
              component={ChapterEditorComponent}
              exact
            ></Route>

            <Route path="/pieChartTest" component={pieChartTest} exact></Route>
            <Route path="/questionTest" component={QuestionTest} exact></Route>
            <Route path="/videoTest" component={VideoTest} exact></Route>
          </>
        </Switch>
      </BrowserRouter>

      {playing && (
        <img
          src={`/assets/background_05.png`}
          alt="container"
          onClick={() => setPlaying(false)}
          className={css`
            position: fixed;
            bottom: 20px;
            right: 20px;
            cursor: pointer;
            z-index: 200;
          `}
        />
      )}
    </>
  );
};

const preLaunch = false;

function App() {
  const [popupState, setPopupState] = useState({
    setContent: (children: JSX.Element | null, title?: string | null) => {
      setPopupState((popupState) => ({
        ...popupState,
        isShown: true,
        children,
        title,
      }));
    },
    children: null as JSX.Element | null,
    title: null as string | null | undefined,
  });

  const [globalState, setGlobalState] = useState({
    selectedVoorstel: null,
    voorstellen: undefined,
    setState: (state: any) =>
      setGlobalState((globalState: any) => ({ ...globalState, ...state })),
  });

  const [firebaseState, setFirebaseState] = useState<Firebase | null>(null);
  useEffect(() => {
    const firebase = new Firebase();
    setFirebaseState(firebase);
    firebase.app
      .auth()
      .signInAnonymously()
      .then(() => {})
      .catch((error: any) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error({ errorCode, errorMessage });
      });
  }, []);

  if (!firebaseState) {
    return <div></div>;
  }
  return (
    <div className="App">
      <GlobalStateContext.Provider value={globalState}>
        <PopupContext.Provider value={popupState}>
          <FirebaseContext.Provider value={firebaseState}>
            <Content />
            {popupState.children && (
              <PopUp title={popupState.title}>{popupState.children}</PopUp>
            )}
          </FirebaseContext.Provider>
        </PopupContext.Provider>
      </GlobalStateContext.Provider>
    </div>
  );
}

export default App;
