import { firestore } from "firebase";
import React, { useContext, useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { FirebaseContext } from "../firebase";
import { GlobalStateContext } from "../global-state";
import { planMapping } from "../utils/plan-mapping";

export const VoorstellenQuery: React.FC<{
  tag?: string;
  questions?: string[];
}> = ({ tag, questions }) => {
  const firebase = useContext(FirebaseContext);
  const globalState = useContext(GlobalStateContext);
  const [voorstellen, loading, error] = useCollection(
    tag
      ? firebase.firestore
          .collection("collected_data")
          .where("answer_type", "==", "plan")
          .where("answer_tag", "==", tag)
          .where(
            "question_id",
            "in",
            planMapping.map((item) => item.planId)
          )
      : firebase.firestore
          .collection("collected_data")
          .where("question_id", "in", questions ? questions : [])
  );
  useEffect(() => {
    const docs = voorstellen
      ? voorstellen.docs.reduce(
          (acc: firestore.QueryDocumentSnapshot<Voorstel>[], doc: any) => {
            let isAlreadyFound = false;
            acc = acc.filter((item) => {
              isAlreadyFound =
                isAlreadyFound ||
                (item.data().question_id === doc.data().question_id &&
                  item.data() &&
                  item.data().answer_text &&
                  doc.data().answer_text &&
                  item.data().answer_text.length >=
                    doc.data().answer_text.length);
              return !(
                item.data().question_id === doc.data().question_id &&
                item.data &&
                item.data().answer_text &&
                doc.data().answer_text &&
                item.data().answer_text.length < doc.data().answer_text.length
              );
            });
            if (!isAlreadyFound) {
              acc.push(doc as firestore.QueryDocumentSnapshot<Voorstel>);
            }
            return acc;
          },
          [] as firestore.QueryDocumentSnapshot<Voorstel>[]
        )
      : [];

    globalState.setState({
      voorstellen: {
        ...voorstellen,
        docs,
      },
    });
    return () => {
      console.log("eyo");
      globalState.setState({ voorstellen: null, selectedVoorstel: null });
    };
  }, [voorstellen]);
  return <> </>;
};
