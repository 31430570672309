import styled from "@emotion/styled";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "emotion";
import * as firebase from "firebase";
import React, { HTMLProps, useContext, useEffect } from "react";
import { GlobalStateContext } from "../../../global-state";
import { PopupContext } from "../../pop-up";

const VoorstelItem = styled.div`
  margin-bottom: 16px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

// const globalState.voorstellen = [
//   "Minder grond voor landbouw, meer voor duurzame woningen. Oproep aan de politiek en het bedrijfsleven / wooncoorporaties.",
//   "In Amsterdam is er teveel plastic op straat. Ons voorstel om dit probleem op te lossen is dat iedereen verplicht een uur per maand moet prikken, daartegenover staat een vergoeding.",
//   "Stoeptegels",
//   "test",
//   "Alle stoeptegels verwijderen in tuinen.",
//   "Alle stoeptegels verwijderen!",
//   "Testplan",
//   "test",
// ];

const QuestionSelector = (props: Props & HTMLProps<HTMLDivElement>) => {
  const { element } = props;
  const popupContext = useContext(PopupContext);
  const globalState = useContext(GlobalStateContext);
  const handleVoorstelClick = (voorstel: any) => {
    // setSelectedVoorstel(voorstel);
    globalState.setState({ selectedVoorstel: voorstel });
    popupContext.setContent(null);
  };

  useEffect(() => {
    if (!globalState.selectedVoorstel && globalState.voorstellen) {
      const randomVoorstelIndex = Math.floor(
        Math.random() * globalState.voorstellen.docs.length
      );
      globalState.setState({
        selectedVoorstel: globalState.voorstellen.docs[randomVoorstelIndex],
      });
    }
  }, [globalState.voorstellen, globalState.selectedVoorstel]);

  return (
    <>
      <div
        className={css`
          position: absolute;
          left: ${element.position ? element.position.x + "%" : "0"};
          top: ${element.position ? element.position.y + "%" : "0"};
          width: ${element.size ? element.size.width + "%" : "0"};
          height: ${element.size ? element.size.height + "%" : "0"};
          background: rgba(0, 0, 0, 0.7);
          color: rgb(227, 173, 22);
          font-weight: bold;
          padding: 4vh 8vh 4vh 4vh;
          cursor: pointer;
        `}
        onClick={() =>
          globalState.voorstellen &&
          popupContext.setContent(
            <div>
              {globalState.voorstellen.docs.map(
                (voorstel: firebase.firestore.DocumentSnapshot<Answer>) => (
                  <VoorstelItem onClick={() => handleVoorstelClick(voorstel)}>
                    {voorstel && voorstel.data()!.answer_text}
                  </VoorstelItem>
                )
              )}
            </div>,
            "Selecteer een voorstel"
          )
        }
      >
        <div
          className={css`
            margin-bottom: 1vh;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 5vh;
          `}
        >
          voorstel
        </div>
        <div
          className={css`
            font-size: 2.2vh;
          `}
        >
          {globalState.selectedVoorstel &&
            globalState.selectedVoorstel.exists &&
            globalState.selectedVoorstel.data()?.answer_text}
        </div>
        <FontAwesomeIcon
          className={css`
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
          `}
          icon={faChevronDown}
        />
      </div>
    </>
  );
};

export default QuestionSelector;
