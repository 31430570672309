import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "emotion";
import React, { useContext, useState } from "react";

// text nog in een apart bestand zetten

const PopUp: React.FC<{ title?: string | null }> = (props) => {
  const popupState = useContext(PopupContext);
  const { title } = props;
  return (
    <div
      className={css`
        top: 20px;
        left: 20px;
        bottom: 20px;
        right: 20px;
        background: #000000;
        color: #e6b016;
        z-index: 10000;
        position: fixed;
        padding: 50px;
      `}
    >
      <div className="intro-screen-text">
        {title && <div className="intro-text bold">{title}</div>}
        <div
          className="intro-text"
          onScroll={(e) => {
            e.stopPropagation();
            console.log(e);
          }}
        >
          {props.children}
        </div>
      </div>

      <FontAwesomeIcon
        className={css`
          position: absolute;
          top: 16px;
          right: 16px;
          font-size: 32px;
          cursor: pointer;
        `}
        icon={faTimes}
        onClick={() => popupState.setContent(null)}
      />
    </div>
  );
};

export const PopupContext = React.createContext<{
  setContent: (children: JSX.Element | null, title?: string | null) => void;
  children: JSX.Element | null;
  title: string | null | undefined;
}>({
  setContent: () => {},
  children: null,
  title: null,
});

export default PopUp;
